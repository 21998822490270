const mutations = {
  setData(state, data) {
    state.data = data;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },

  setDataByApplication(state, dataByApplication) {
    state.dataByApplication = dataByApplication;
  },

  setLoadingByApplication(state, loadingByApplication) {
    state.loadingByApplication = loadingByApplication;
  }
};

export default mutations;
