import i18n from "@/i18n";

const getters = {
  /* Get details of a application */
  getDetails: state => () => {
    const locale = i18n.locale;

    if (state.data && state.data.application_details) {
      const application_details = state.data.application_details.find(
        item => item.language === locale
      );

      return application_details ? application_details : "";
    } else {
      return "";
    }
  },

  /* Get main_image_url of a application */
  getMainImageUrl: (state, getters) => () => {
    return getters.getDetails().main_image_url
      ? `${process.env.VUE_APP_BASE_URL}${getters.getDetails().main_image_url}`
      : "";
  },

  /* Get title of a application */
  getTitle: (state, getters) => () => {
    return getters.getDetails().title ? getters.getDetails().title : "";
  },

  /* Get subtitle of a application */
  getSubtitle: (state, getters) => () => {
    return getters.getDetails().subtitle ? getters.getDetails().subtitle : "";
  },

  /* Get align_text_in_main_image of a application */
  getAlignTextInImage: (state, getters) => () => {
    return getters.getDetails().align_text_in_main_image
      ? getters.getDetails().align_text_in_main_image
      : "";
  },

  /* Get architecture_title of a application */
  getArchitectureTitle: (state, getters) => () => {
    return getters.getDetails().architecture_title
      ? getters.getDetails().architecture_title
      : "";
  },

  /* Get architecture_description of a application */
  getArchitectureDescription: (state, getters) => () => {
    return getters.getDetails().architecture_description
      ? getters.getDetails().architecture_description.split("\n")
      : "";
  },

  /* Get link_text of a application */
  getLinkText: (state, getters) => () => {
    return getters.getDetails().link_text ? getters.getDetails().link_text : "";
  },

  /* Get link_to_see_more of a application */
  getLinkToSeeMore: (state, getters) => () => {
    return getters.getDetails().link_to_see_more
      ? getters.getDetails().link_to_see_more
      : "";
  },

  /* Get architecture_image_url of a application */
  getArchitectureImageUrl: (state, getters) => () => {
    return getters.getDetails().architecture_image_url
      ? `${process.env.VUE_APP_BASE_URL}${
          getters.getDetails().architecture_image_url
        }`
      : "";
  },

  /* Get show_product_section of a application */
  getShowProductSection: (state, getters) => () => {
    return getters.getDetails().show_product_section
      ? getters.getDetails().show_product_section
      : 1;
  },

  /* Get application_tags of a application */
  getApplicationTags: (state, getters) => () => {
    return getters.getDetails().application_tags
      ? getters.getDetails().application_tags
      : [];
  },

  /* Get products of a application */
  getProducts: (state, getters) => () => {
    const locale = i18n.locale;

    if (state.data && state.data.products && state.data.products.length) {
      const productsToShow = state.data.products.map(product => {
        const {
          product_id,
          product_details,
          models,
          application_ids,
          tags_nickname,
          uses
        } = product;

        const productDetailsToShow = product_details.find(
          item => item.language === locale
        );

        const modelsToShow = models.map(item => {
          const { image_resources, images_order } = item;

          const imageResourcesToShow = image_resources.map(item => {
            const { image_url, model_resource_id } = item;

            /* Find position */
            const position = images_order.findIndex(
              imageResourceId => imageResourceId === model_resource_id
            );

            const itemToShow = {
              image_url: `${process.env.VUE_APP_BASE_URL}${image_url}`,
              model_resource_id,
              position
            };

            return itemToShow;
          });

          /* Order asc */
          const orderedImageResourcesToShow = imageResourcesToShow.sort(
            (a, b) => a.position - b.position
          );

          const itemToShow = {
            image_resources: orderedImageResourcesToShow,
            images_order
          };

          return itemToShow;
        });

        /* Get application tags */
        const applicationTags = getters.getApplicationTags();
        let tagsToShow = [];

        /* Get product tags by application tags */
        if (applicationTags.length) {
          tags_nickname.forEach(productTag => {
            const findTag = applicationTags.find(appTag => {
              return appTag.nickname === productTag;
            });

            findTag ? tagsToShow.push(findTag.nickname) : null;
          });
        }

        const productToShow = {
          product_id,
          product_details: productDetailsToShow,
          models: modelsToShow,
          application_ids,
          tags_nickname: tagsToShow,
          uses
        };

        return productToShow;
      });

      return productsToShow;
    } else {
      return [];
    }
  },

  /* Get use_images_per_language of a application */
  getUseImagesPerLanguage: (state, getters) => () => {
    return getters.getDetails().use_images_per_language
      ? getters.getDetails().use_images_per_language
      : 1;
  },

  getLastSpaceColor: (state, getters) => () => {
    return getters.getShowProductSection()
      ? "rgb(255,255,255) !important"
      : "rgb(248,248,248) !important";
  }
};

export default getters;
