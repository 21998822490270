import axios from "axios";

const actions = {
  /* Get site data */
  read({ commit }) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      axios
        .get(`/api/sites`)
        .then(response => {
          const site = response.data.data.find(
            item => item.name === process.env.VUE_APP_SITE_NAME
          );
          commit("setData", site);
          resolve(site);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    });
  }
};

export default actions;
