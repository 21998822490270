<template>
  <v-main class="pa-0">
    <section id="SiteComponent">
      <SiteComponent :data="getSite()"></SiteComponent>
    </section>

    <section id="ConceptComponent" class="concept-section">
      <ConceptComponent :data="getConcept()"></ConceptComponent>
    </section>

    <section
      v-for="(section, index) in getSections()"
      :key="section.section_id"
      :id="`section-${section.section_id}`"
      :style="{ backgroundColor: section.section_details.backgroundColor }"
    >
      <ApplicationComponent
        :data="section.section_details"
      ></ApplicationComponent>
      <v-col
        v-if="index < getSections().length - 1"
        class="application-space"
      ></v-col>
    </section>

    <section
      id="SiteLastSpace"
      :style="{ backgroundColor: getBottomGapColor() }"
    >
      <v-col class="last-space"></v-col>
    </section>
  </v-main>
</template>

<script>
import SiteComponent from "@/components/Site/SiteComponent.vue";
import ConceptComponent from "@/components/Site/ConceptComponent.vue";
import ApplicationComponent from "@/components/Site/AppComponent.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    SiteComponent,
    ConceptComponent,
    ApplicationComponent,
  },
  data: () => ({}),
  created() {},
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState("site", {
      data: (state) => state.data,
      loading: (state) => state.loading,
    }),
    ...mapGetters("site", {
      getSite: "getSite",
      getConcept: "getConcept",
      getBottomGapColor: "getBottomGapColor",
    }),
    ...mapGetters("section", {
      getSections: "getSections",
    }),
  },
};
</script>

<style scoped>
.concept-section {
  margin-bottom: 1.6667vw;
}

.application-space {
  padding: 0.093584vw;
  background-color: rgb(255, 255, 255);
}
.last-space {
  padding: 0.625vw;
}
@media screen and (max-width: 426px) {
  .application-space {
    padding: 5px;
    background-color: rgb(255, 255, 255);
  }
  .last-space {
    padding: 5px;
  }
}
</style>
