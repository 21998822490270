export function getAlignment(value) {
  const align = {
    start: "start",
    center: "center",
    end: "end"
  };
  switch (value) {
    case "start":
    case "left":
      return align.start;
    case "center":
      return align.center;
    case "end":
    case "right":
      return align.end;
    default:
      return align.start;
  }
}
