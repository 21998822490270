<template>
  <div>
    <v-col cols="auto" class="related-container">
      <v-row no-gutters class="mt-8 related-title-content">
        <span class="related-title-font">{{
          $t("application.relatedProduct.title")
        }}</span>
      </v-row>

      <v-row v-if="!products.length" no-gutters class="mt-1" justify="center">
        <v-col cols="auto" class="mb-4 mt-4">
          <span class="mt-5 related-not-found-font">{{
            $t("application.relatedProduct.notFound")
          }}</span>
        </v-col>
      </v-row>

      <v-row v-else no-gutters class="grid mt-1 product-container">
        <v-col
          v-for="(product, index) in products"
          :key="index"
          class="ml-6 mb-4 mt-4 cell"
        >
          <ProductHome
            :name="product.product_details.name"
            :newProduct="index === 1 ? true : false"
            :idProducto="product.product_id"
            :data="product"
          />
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import ProductHome from "@/components/Application/ProductHome.vue";
export default {
  name: "RelatedProd",
  components: {
    ProductHome,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    products() {
      return this.data ? this.data : [];
    },
  },
};
</script>

<style>
.related-title-font {
  font-family: Dm Sans !important;
  color: rgb(0, 32, 79);
  font-weight: 400;
  font-size: 30px;
}
.related-not-found-font {
  font-family: Dm Sans !important;
  font-size: 24px;
  letter-spacing: -0.1px;
  color: #113353;
}

.grid {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.related-title-content {
  margin-left: 7.5%;
}

.grid:last-child {
  margin-bottom: 30px !important;
}

@media screen and (max-width: 1600px) {
  .related-title-font {
    font-family: Dm Sans !important;
    color: rgb(0, 32, 79);
    font-size: 1.875vw !important;
    font-weight: 400 !important;
  }
  .related-not-found-font {
    font-family: Dm Sans !important;
    font-size: 1.5vw !important;
    letter-spacing: -0.1px;
    color: #113353;
  }
}
@media screen and (max-width: 769px) {
  .related-title-font {
    font-family: Dm Sans !important;
    color: rgb(0, 32, 79);
    font-size: 25px !important;
    font-weight: 400 !important;
  }
  .related-not-found-font {
    font-family: Dm Sans !important;
    font-size: 25px !important;
    letter-spacing: -0.1px;
    color: #113353;
  }

  .grid {
    display: flex;
    justify-content: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .related-title-content {
    margin-left: 3.5%;
  }
}

@media screen and (max-width: 767px) {
  .related-title-font {
    font-family: Dm Sans !important;
    color: rgb(0, 32, 79);
    font-size: 3.255vw !important;
    font-weight: 400 !important;
  }
  .related-not-found-font {
    font-family: Dm Sans !important;
    font-size: 3.255vw !important;
    letter-spacing: -0.013vw px;
    color: #113353;
  }

  .grid {
    display: flex;
    justify-content: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .related-title-content {
    margin-left: 3.5%;
  }
}

@media screen and (max-width: 426px) {
  .related-title-font {
    font-family: Dm Sans !important;
    color: rgb(0, 32, 79);
    font-size: 20px !important;
    font-weight: 400 !important;
  }
  .related-not-found-font {
    font-family: Dm Sans !important;
    font-size: 3.255vw !important;
    letter-spacing: -0.013vw px;
    color: #113353;
  }

  .grid {
    display: flex;
    justify-content: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .related-title-content {
    margin-left: 3.5%;
  }
}

@media screen and (max-width: 424px) {
  .related-title-font {
    font-family: Dm Sans !important;
    color: rgb(0, 32, 79);
    font-size: 4.695vw !important;
    font-weight: 400 !important;
  }
  .related-not-found-font {
    font-family: Dm Sans !important;
    font-size: 3.255vw !important;
    letter-spacing: -0.013vw;
    color: #113353;
  }

  .grid {
    display: flex;
    justify-content: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .related-title-content {
    margin-left: 3.5%;
  }
}
.related-container {
  background-color: rgb(248, 248, 248);
}

.related-title-text {
  color: rgb(0, 32, 79);
  font-weight: 400;
  font-size: 30px;
}

.related-not-found-text {
  font-size: 24px;
  letter-spacing: -0.1px;
  color: #113353;
}

.product-container {
  margin-right: 6.5% !important;
  margin-left: 7% !important;
}

.cell {
  flex: none;
  max-width: 280px;
  max-height: 250px;
}
</style>
