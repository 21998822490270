<template>
  <v-card tile flat class="product-home-card">
    <v-row no-gutters class="effect product-home-container">
      <v-col cols="12" class="pr-5 pt-5 text-end">
        <v-chip
          v-for="(item, i) in data.tags_nickname.slice(0, 2)"
          :key="i"
          :class="i < data.tags_nickname.slice(0, 2).length - 1 ? 'mr-2' : null"
          small
          color="#113353"
          label
          text-color="white"
          >{{ item }}</v-chip
        >
      </v-col>
      <v-col cols="12" class="centrar">
        <v-img
          class="shrink img"
          contain
          :src="getPrductImg(data.models)"
          max-height="89.250"
        ></v-img>
      </v-col>

      <v-col cols="12" class="centrar nameProduct mb-5">
        <span class="ml-11 mr-11 product-name-font">{{ name }}</span>
      </v-col>

      <v-col cols="12" class="centrar moreProductbtn mb-5">
        <v-btn
          class="product-home-button"
          color="rgb(0,32,79)"
          dark
          depressed
          small
          tile
          @click="$router.push({ path: `/product/${idProducto}` })"
          >{{ $t("productHome.button.more") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "ProductHome",
  props: {
    name: {
      type: String,
      default: "",
    },
    img: {
      type: String,
      default: "",
    },
    newProduct: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: "",
    },
    idProducto: {
      type: Number,
    },
    data: {
      type: Object,
    },
  },
  data: function() {
    return {};
  },
  methods: {
    getPrductImg(models) {
      let productImg = "";
      if (models.length && models[0].image_resources) {
        const images = models[0].image_resources;
        if (images.length && images[0].image_url) {
          productImg = images[0].image_url;
        }
      }

      return productImg;
    },
  },
};
</script>

<style scoped>
.product-name-font {
  font-family: Dm Sans !important;
  font-size: 24px !important;
  color: rgb(47, 95, 169) !important;
  line-height: 1.1 !important;
  text-align: center !important;
}

.product-home-card {
  height: 257px !important;
  width: 280px !important;
  border-radius: 6px !important;
}
.product-home-container {
  height: 100% !important;
}

.product-home-name-text {
  font-size: 24px !important;
  color: rgb(47, 95, 169) !important;
  line-height: 1.1 !important;
  text-align: center !important;
}

.product-home-button {
  width: 50% !important;
  text-transform: capitalize !important;
  letter-spacing: 0px !important;
  font-size: 14px !important;
}

.centrar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.moreProductbtn {
  margin-top: 5px;
  display: none;
}

.effect:hover {
  transition: box-shadow 0.2s;
  -webkit-box-shadow: 0px 0px 9px 3px rgba(2, 31, 73, 0.29);
  -moz-box-shadow: 0px 0px 9px 3px rgba(2, 31, 73, 0.29);
  box-shadow: 0px 0px 9px 3px rgba(2, 31, 73, 0.29);
}

.effect .img {
  width: 170px;
  transition: transform 0.6s;
}

.effect:hover .img {
  transform: scale(1.2);
  width: 190px;
}

.effect:hover .nameProduct {
  display: none;
}
.nameProduct {
  margin-top: 5px;
}

.effect:hover .moreProductbtn {
  transition: display 0.8s;
  display: flex;
}

.new {
  height: 25px;
  background-color: rgb(60, 104, 169);
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (max-width: 1600px) {
  .product-name-font {
    font-family: Dm Sans !important;
    font-size: 1.5vw !important;
    color: rgb(47, 95, 169) !important;
    line-height: 1.1 !important;
    text-align: center !important;
  }
}
@media screen and (max-width: 769px) {
  .product-name-font {
    font-family: Dm Sans !important;
    font-size: 20px !important;
    color: rgb(47, 95, 169) !important;
    line-height: 1.1 !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 767px) {
  .product-name-font {
    font-family: Dm Sans !important;
    font-size: 2.604vw !important;
    color: rgb(47, 95, 169) !important;
    line-height: 1.1 !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 426px) {
  .product-name-font {
    font-family: Dm Sans !important;
    font-size: 14px !important;
    color: rgb(47, 95, 169) !important;
    line-height: 1.1 !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 424px) {
  .product-name-font {
    font-family: Dm Sans !important;
    font-size: 3.286vw !important;
    color: rgb(47, 95, 169) !important;
    line-height: 1.1 !important;
    text-align: center !important;
  }
}
</style>
