<template>
  <v-app style="background-color: #fbfbfb !important;">
    <v-col
      align-self="center"
      style="width:1600px; background-color:white; padding:0px"
    >
      <v-main>
        <Navigation hide-menu v-if="!mobile" />
        <NavigationMobile hide-menu v-else />
      </v-main>
      <Footer
        :localAreaTitle="$t('footer.solutionsAreas.title')"
        :localAreaItems="localAreas"
      />
    </v-col>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import NavigationMobile from "@/components/NavigationMobile.vue";
import Footer from "@/components/Footer.vue";

import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    Navigation,
    NavigationMobile,
    Footer,
  },
  data: () => ({}),
  created() {
    this.read();
  },
  computed: {
    ...mapGetters("section", {
      getSections: "getSections",
    }),
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    localAreas() {
      const localSections = this.getSections().map((item) => {
        const { section_id, section_details } = item;
        const show = {
          id: `section-${section_id}`,
          title: section_details.title,
        };
        return show;
      });
      console.log(localSections)
      return localSections;
    },
  },
  methods: {
    read() {
      this.$store
        .dispatch("site/read")
        .then((res) => {
          this.$store
            .dispatch("section/readBySite", res.name)
            .then(() => {})
            .catch(() => {});
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&family=Roboto&family=Source+Sans+Pro&display=swap");
html,
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans" !important;
}
span {
  font-family: "DM Sans" !important;
}
.v-text-field--outlined:not(.v-input--is-focused) fieldset {
  color: rgb(60, 104, 169) !important;
  text-shadow: none;
}
/*Input border color*/
.v-text-field--outlined.v-input--is-focused fieldset {
  border: 2px solid rgb(0, 32, 79);
}
/* Input label color */
.v-input .v-label {
  text-shadow: none;
  color: rgb(60, 104, 169) !important;
  opacity: 1;
}
/* Input label color (focused) */
.v-input.v-text-field--outlined.v-input--is-focused .v-label {
  color: rgb(0, 32, 79) !important;
  opacity: 1;
}
/* input placeholder color */
.v-input input::placeholder {
  text-shadow: none;
  color: rgb(198, 198, 198) !important;
  opacity: 1;
}

/* hover color */
.v-select-list .v-list-item:hover {
  background-color: rgb(239, 239, 239);
}

input {
  color: rgb(0, 32, 79) !important;
}
.v-input {
  color: rgb(0, 32, 79) !important;
}
/* Selected element color on a v-select input */
.v-select__selection {
  color: rgb(0, 32, 79) !important;
}
</style>
