<template>
  <div
    class="concept-container d-flex flex-column justify-space-between align-center"
  >
    <v-img
      :src="img"
      contain
      :max-height="maxHeight"
      class="align-start"
    ></v-img>
    <v-row
      no-gutters
      class="concept-see-more-container"
      data-aos="fade-in"
      data-aos-duration="1000"
      data-aos-delay="400"
      justify="center"
    >
      <v-col cols="12" md="auto" class="text-center concept-see-more">
        <a
          :href="seeMoreUrl"
          class="text-decoration-none concept-see-more-text"
        >
          <strong>
            <span class="concept-see-more-line">{{ seeMoreText }}</span>
          </strong>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ConceptComponent",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    img() {
      return this.data.img ? this.data.img : "";
    },
    seeMoreText() {
      return this.data.seeMoreText ? this.data.seeMoreText : "";
    },
    seeMoreUrl() {
      return this.data.seeMoreUrl ? this.data.seeMoreUrl : "";
    },
    maxHeight() {
      console.log(this.$vuetify.breakpoint);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return null;
        case "sm":
          return this.$vuetify.breakpoint.width * 0.4;
        case "md":
          return this.$vuetify.breakpoint.width * 0.4;
        case "lg":
          return this.$vuetify.breakpoint.width * 0.4;
        default:
          return this.$vuetify.breakpoint.width * 0.4;
      }
    },
  },
};
</script>

<style scoped>
.concept-container {
  padding-top: 1.667%;
}

.concept-see-more-container {
  padding-top: 0.8335%;
}

.concept-see-more {
  line-height: 1.2 !important;
}

.concept-see-more-text {
  font-family: DM Sans;
  color: rgb(15, 51, 82) !important;
  font-size: 0.8853vw !important;
  letter-spacing: 0.0225em !important;
  font-weight: 500 !important;
}

.concept-see-more-line {
  display: inline-block;
  border-bottom: 0.1045vw solid #f41f1f;
  padding-bottom: 0.795%;
}

@media screen and (max-width: 769px) {
  .concept-see-more-text {
    font-family: DM Sans;
    color: rgb(15, 51, 82) !important;
    font-size: 12px !important;
    letter-spacing: 0.0225em !important;
    font-weight: 500 !important;
  }
}

@media screen and (max-width: 767px) {
  .concept-see-more-text {
    font-family: DM Sans;
    color: rgb(15, 51, 82) !important;
    font-size: 1.563vw !important;
    letter-spacing: 0.0225em !important;
    font-weight: 500 !important;
  }
}
@media screen and (max-width: 426px) {
  .concept-container {
    padding-top: 3%;
  }
  .concept-see-more-text {
    font-family: DM Sans;
    color: rgb(15, 51, 82) !important;
    font-size: 10px !important;
    letter-spacing: 0.0225em !important;
    font-weight: 500 !important;
  }
}
@media screen and (max-width: 424px) {
  .concept-container {
    padding-top: 3%;
  }
  .concept-see-more-text {
    font-family: DM Sans;
    color: rgb(15, 51, 82) !important;
    font-size: 2.358vw !important;
    letter-spacing: 0.0225em !important;
    font-weight: 500 !important;
  }
}
</style>
