import Vue from "vue";
import Vuex from "vuex";
import site from "./modules/site";
import section from "./modules/section";
import application from "./modules/application";
import product from "./modules/product";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { site, section, application, product }
});
