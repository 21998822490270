<template>
  <div
    class="concept-container d-flex flex-column justify-space-between align-center"
  >
    <!-- Image -->
    <!-- <v-img height="480" :src="data.img" class="white--text align-start"> -->
    <v-img :src="img" :max-height="maxHeight" class="align-start height-image">
      <!-- Placeholder loader on image -->
      <template v-slot:placeholder>
        <LoadingComponent type="linear" />
      </template>

      <!-- Text on image -->
      <v-row
        no-gutters
        class="site-container"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <v-col cols="12">
          <!-- Title -->
          <v-row no-gutters :justify="alignText.content">
            <v-col cols="auto">
              <div
                class="title-font transition-swing"
                :style="{ color: textColor }"
                v-text="title"
              />
            </v-col>
          </v-row>

          <!-- Subtitle -->
          <v-row
            no-gutters
            :class="[alignText.padding]"
            :justify="alignText.content"
          >
            <v-col cols="auto" class="site-subtitle">
              <div
                :class="[alignText.text, 'subtitle-font transition-swing']"
                :style="{ color: textColor }"
                v-text="subtitle"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<script>
import { getAlignment } from "@/utils/-.js";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  name: "SiteComponent",
  components: {
    LoadingComponent,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    img() {
      return this.data.img ? this.data.img : "";
    },
    title() {
      return this.data.title ? this.data.title : "";
    },
    subtitle() {
      return this.data.subtitle ? this.data.subtitle : "";
    },
    alignText() {
      const alignReq = this.data.alignText ? this.data.alignText : undefined;
      const align = getAlignment(alignReq);

      return {
        content: align,
        text: `text-${align}`,
        padding: `site-subtitle-container-${align}`,
      };
    },
    textColor() {
      return this.data.color
        ? this.data.color + "!important"
        : "rgb(15, 51, 82) !important";
    },
    maxHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return null;
        case "sm":
          return this.$vuetify.breakpoint.width * 0.45;
        case "md":
          return this.$vuetify.breakpoint.width * 0.45;
        case "lg":
          return this.$vuetify.breakpoint.width * 0.45;
        default:
          return this.$vuetify.breakpoint.width * 0.45;
      }
    },
  },
};
</script>

<style scoped>
.title-font {
  font-size: 72px;
  font-weight: 400 !important;
  letter-spacing: 0.0225em !important;
  font-family: Dm Sans !important;
}

.subtitle-font {
  font-size: 20px;
  font-weight: 400 !important;
  letter-spacing: -0.08px !important;
  line-height: 30px;
  font-family: Dm Sans !important;
}

.height-image {
  height: 100%;
}

.site-container {
  padding-top: 4.75vw !important;
  padding-left: 6.95vw !important;
  padding-right: 6.95vw !important;
}

.site-subtitle-container-start {
  padding-top: 0.5vw !important;
  padding-right: 28vw !important;
}

.site-subtitle-container-center {
  padding-top: 0.5vw !important;
}

.site-subtitle-container-end {
  padding-top: 0.5vw !important;
  padding-left: 27vw !important;
}

@media screen and (max-width: 1600px) {
  .title-font {
    font-family: Dm Sans !important;
    font-size: 4.5vw !important;
    font-weight: 400 !important;
    letter-spacing: 0.0225em !important;
  }
  .subtitle-font {
    font-family: Dm Sans !important;
    font-size: 1.25vw !important;
    font-weight: 400 !important;
    letter-spacing: -0.08px !important;
    line-height: 1.875vw !important;
  }

  .title-app-font {
    font-size: 3.5156vw !important;
    font-weight: 400 !important;
    line-height: 65px;
    font-family: Dm Sans !important;
  }

  .subtitle-app-font {
    font-size: 1.1719vw !important;
    font-weight: 400 !important;

    line-height: 27px;
    font-family: Dm Sans !important;
  }
}

@media screen and (max-width: 769px) {
  .title-font {
    font-family: Dm Sans !important;
    font-size: 36px !important;
    font-weight: 400 !important;
    letter-spacing: 0.0225em !important;
  }
  .subtitle-font {
    font-family: Dm Sans !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    letter-spacing: -0.08px !important;
    line-height: 17px !important;
  }
}

@media screen and (max-width: 767px) {
  .title-font {
    font-family: Dm Sans !important;
    font-size: 4.688vw !important;
    font-weight: 400 !important;
    letter-spacing: 0.0225em !important;
  }
  .subtitle-font {
    font-family: Dm Sans !important;
    font-size: 1.693vw !important;
    font-weight: 400 !important;
    letter-spacing: -0.01vw !important;
    line-height: 2.214vw !important;
  }
}
@media screen and (max-width: 426px) {
  .height-image {
    height: 60vw;
  }
  .title-font {
    font-family: Dm Sans !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    letter-spacing: 0.0225em !important;
  }
  .subtitle-font {
    font-family: Dm Sans !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    letter-spacing: -0.01vw !important;
    line-height: 12px !important;
  }
}

@media screen and (max-width: 424px) {
  .height-image {
    height: 60vw;
  }
  .title-font {
    font-family: Dm Sans !important;
    font-size: 5.189vw !important;
    font-weight: 400 !important;
    letter-spacing: 0.0225em !important;
  }
  .subtitle-font {
    font-family: Dm Sans !important;
    font-size: 2.594vw !important;
    font-weight: 400 !important;
    letter-spacing: -0.01vw !important;
    line-height: 2.83vw !important;
  }
  .site-subtitle-container-start {
    padding-top: 0.943vw !important;
    padding-right: 7.075vw !important;
  }
}
</style>
