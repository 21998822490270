import i18n from "@/i18n";

const getters = {
  /* Get details of a product */
  getDetails: state => () => {
    const locale = i18n.locale;

    if (state.data && state.data.product_details) {
      const product_details = state.data.product_details.find(
        item => item.language === locale
      );

      return product_details ? product_details : "";
    } else {
      return "";
    }
  },

  /* Get name of a product */
  getName: (state, getters) => () => {
    return getters.getDetails().name ? getters.getDetails().name : "";
  },

  /* Get tags_nickname of a product */
  getTagsNickname: state => () => {
    return state.data && state.data.tags_nickname
      ? state.data.tags_nickname.slice(0, 3)
      : [];
  },

  /* Get short description of a product */
  getShortDescription: (state, getters) => () => {
    return getters.getDetails().short_description
      ? getters.getDetails().short_description
      : "";
  },

  /* Get long description of a product */
  getLongDescription: (state, getters) => () => {
    return getters.getDetails().long_description
      ? getters.getDetails().long_description.split("\n")
      : [];
  },

  /* Get uses of a product */
  getUses: state => () => {
    const uses = state.data && state.data.uses ? state.data.uses : [];

    const usesToShow = uses.map(item => {
      const locale = i18n.locale;

      const { use_id, use_details } = item;

      const useDetailsByLanguage = use_details.find(
        item => item.language_slug === locale
      );

      const { id, name, language_slug, icon_url } = useDetailsByLanguage
        ? useDetailsByLanguage
        : {};

      const itemToShow = {
        use_id,
        use_details: {
          id,
          name,
          language_slug,
          icon_url: icon_url ? `${process.env.VUE_APP_BASE_URL}${icon_url}` : ""
        }
      };

      return itemToShow;
    });

    return usesToShow;
  },

  /* Get models of a product */
  getModels: state => () => {
    const models = state.data && state.data.models ? state.data.models : [];

    const modelsToShow = models.map(item => {
      const {
        model_id,
        name,
        characteristics,
        image_resources,
        images_order,
        product_id
      } = item;

      const modelCharacteristicsToShow = characteristics.map(item => {
        const { characteristic_id, characteristic_details } = item;

        const locale = i18n.locale;

        const modelCharacteristicDetailsToShow = characteristic_details.find(
          item => item.language_slug === locale
        );

        const {
          characteristic_detail_id,
          name,
          language_slug,
          value,
          value_id
        } = modelCharacteristicDetailsToShow
          ? modelCharacteristicDetailsToShow
          : {};

        const itemToShow = {
          characteristic_id,
          characteristic_details: {
            characteristic_detail_id,
            name,
            language_slug,
            value,
            value_id
          }
        };

        return itemToShow;
      });

      const imageResourcesToShow = image_resources.map(item => {
        const { image_url, model_resource_id } = item;

        /* Find position */
        const position = images_order.findIndex(
          imageResourceId => imageResourceId === model_resource_id
        );

        const itemToShow = {
          image_url: `${process.env.VUE_APP_BASE_URL}${image_url}`,
          model_resource_id,
          position
        };

        return itemToShow;
      });

      /* Order asc */
      const orderedImageResourcesToShow = imageResourcesToShow.sort(
        (a, b) => a.position - b.position
      );

      const itemToShow = {
        model_id,
        name,
        characteristics: modelCharacteristicsToShow,
        image_resources: orderedImageResourcesToShow,
        images_order,
        product_id
      };

      return itemToShow;
    });

    return modelsToShow;
  },

  /* Get documents of a product */
  getDocuments: state => () => {
    const documents =
      state.data && state.data.documents ? state.data.documents : [];

    const locale = i18n.locale;

    const documentsByLanguage = documents.filter(
      document => document.language_slug === locale
    );

    const documentsToShow = documentsByLanguage.map(document => {
      const {
        document_id,
        language_slug,
        name,
        path,
        document_type
      } = document;
      const { document_type_id, document_type_details } = document_type;
      const documentTypeDetailsToShow = document_type_details.find(
        item => item.language_slug === locale
      );

      return {
        document_id,
        language_slug,
        name,
        path,
        document_type: {
          document_type_id,
          document_type_details: documentTypeDetailsToShow
        }
      };
    });

    return documentsToShow;
  }
};

export default getters;
