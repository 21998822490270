<template>
  <div>
    <v-row
      no-gutters
      class="architecture-container"
      :justify="alignText.content"
      data-aos="fade-in"
      data-aos-duration="1000"
      data-aos-delay="400"
    >
      <v-col :cols="numCol" class="architecture-content" :order="orderText">
        <div
          v-if="title"
          :class="[
            'text-left',
            'architecture-title architecture-title-font transition-swing',
          ]"
          :style="{ color: textColor }"
          v-text="title"
        ></div>

        <div
          v-if="subtitle.length"
          :class="[
            alignText.text,
            subtitleContentClass,
            'architecture-subtitle architecture-subtitle-font transition-swing',
          ]"
        >
          <div v-for="(subtitleItem, i) in subtitle" :key="i">
            {{ subtitleItem }}
            <br />
          </div>
        </div>

        <div
          v-if="!url"
          :class="[
            'text-left',
            urlContentClass,
            'architecture-url transition-swing',
          ]"
        >
          <a
            :href="url"
            :class="[
              'architecture-url-text text-decoration-none transition-swing',
            ]"
          >
            <div
              class="architecture-url-line"
              :style="{ color: textColor }"
              v-text="urlText"
            />
          </a>
        </div>
      </v-col>

      <v-col style="align-self:center" :cols="numCol" :order="orderImage">
        <v-img
          class=" align-center height-image"
          :src="img"
          contain
          :max-height="maxHeight"
        ></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ArchitectureComponent",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    img() {
      return this.data.img ? this.data.img : "";
    },
    alignText() {
      const align = this.data.alignText ? this.data.alignText : "start";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return {
            content: align,
            text: `text-left`,
          };
        case "sm":
          return {
            content: align,
            text: `text-left`,
          };
        case "md":
          return {
            content: align,
            text: `text-${align}`,
          };
        case "lg":
          return {
            content: align,
            text: `text-${align}`,
          };
        default:
          return {
            content: align,
            text: `text-${align}`,
          };
      }
    },
    colOrder() {
      switch (this.data.alignText) {
        case "start":
          return {
            text: 1,
            image: 2,
          };
        case "end":
          return {
            text: 2,
            image: 1,
          };

        default:
          return {
            text: 1,
            image: 2,
          };
      }
    },
    orderText() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 2;
        case "sm":
          return 1;
        case "md":
          return 1;
        case "lg":
          return 1;
        default:
          return 2;
      }
    },
    orderImage() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 2;
        case "md":
          return 2;
        case "lg":
          return 2;
        default:
          return 2;
      }
    },
    numCol() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 12;
        case "sm":
          return 6;
        case "md":
          return 6;
        case "lg":
          return 6;
        default:
          return 6;
      }
    },
    maxHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return null;
        case "sm":
          return this.$vuetify.breakpoint.width * 0.45;
        case "md":
          return this.$vuetify.breakpoint.width * 0.45;
        case "lg":
          return this.$vuetify.breakpoint.width * 0.45;
        default:
          return this.$vuetify.breakpoint.width * 0.45;
      }
    },
    title() {
      return this.data.title ? this.data.title : "";
    },
    subtitle() {
      return this.data.subtitle;
    },
    subtitleContentClass() {
      return this.title ? "architecture-subtitle-dynamic" : null;
    },
    urlText() {
      return this.data.urlText ? this.data.urlText : "Ver más";
    },
    url() {
      return this.data.url ? this.data.url : "";
    },
    urlContentClass() {
      return this.title || this.subtitle ? "architecture-url-dynamic" : null;
    },
    textColor() {
      return this.data.color
        ? this.data.color + "!important"
        : "rgb(0,32,79) !important";
    },
  },
};
</script>

<style scoped>
.architecture-title-font {
  font-size: 46px;
  font-family: Dm Sans !important;
  font-weight: 400 !important;
}

.height-image {
  height: 100%;
}
.architecture-subtitle-font {
  font-size: 18px;
  font-weight: 400 !important;

  line-height: 27px;
  font-family: Dm Sans !important;
}
.architecture-container {
  margin-top: 3.1249vw !important;
  margin-bottom: 1.5624vw !important;
  margin-left: 5.9992vw !important;
  margin-right: 5.9992vw !important;
}

.architecture-content {
  padding-left: 1.4583vw !important;
  padding-right: 1.4583vw !important;
  margin-top: 0px !important;
}

.architecture-title {
  line-height: 46px !important;
  margin-left: 3.6969vw !important;
  margin-right: 3.6969vw !important;
}

.architecture-title-text {
  font-family: DM Sans !important;
  font-size: 3.776vw !important;
}

.architecture-subtitle-dynamic {
  padding-top: 2.1vw !important;
}

.architecture-subtitle {
  line-height: 1.5 !important;
  margin-left: 3.6969vw !important;
  margin-right: 8.6276vw !important;
}

.architecture-subtitle-text {
  font-family: DM Sans !important;
  font-size: 1.1719vw !important;
  letter-spacing: -0.07px !important;
  color: #424244;
}

.architecture-url-dynamic {
  padding-top: 1.0416vw !important;
}

.architecture-url {
  line-height: 1.2 !important;
  margin-left: 3.6969vw !important;
  margin-right: 3.6969vw !important;
}

.architecture-url-text {
  font-family: DM Sans !important;
  color: rgb(255, 255, 255) !important;
  font-size: 20px !important;
  font-weight: "bold" !important;
}

.architecture-url-line {
  display: inline-block;
  border-bottom: 0.1045vw solid #f41f1f;
  padding-bottom: 0.5208vw !important;
}
@media screen and (max-width: 1600px) {
  .architecture-title-font {
    font-family: DM Sans !important;
    font-size: 2.875vw !important;
  }
  .architecture-subtitle-font {
    font-family: DM Sans !important;
    font-size: 1.1719vw !important;
    letter-spacing: -0.07px !important;
    color: #424244;
    font-weight: 400 !important;
  }
  .architecture-url-text {
    font-family: DM Sans !important;
    color: rgb(255, 255, 255) !important;
    font-size: 1.25vw !important;
    font-weight: "bold" !important;
  }
  .architecture-title {
    line-height: 2.875vw !important;
    margin-left: 3.6969vw !important;
    margin-right: 3.6969vw !important;
  }
}
@media screen and (max-width: 769px) {
  .architecture-title-font {
    font-family: DM Sans !important;
    font-size: 20px !important;
  }
  .architecture-container {
    margin-top: 3.1249vw !important;
    margin-bottom: 1.5624vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .architecture-subtitle-font {
    font-family: DM Sans !important;
    font-size: 18px !important;
    letter-spacing: -0.12px !important;
    color: #424244;
    font-weight: 400 !important;
  }
  .architecture-url-text {
    font-family: DM Sans !important;
    color: rgb(255, 255, 255) !important;
    font-size: 20px !important;
    font-weight: "bold" !important;
  }
  .architecture-subtitle {
    line-height: 1.5 !important;
    margin-left: 3.6969vw !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  .architecture-title-font {
    font-family: DM Sans !important;
    font-size: 2.604vw !important;
  }
  .architecture-subtitle-font {
    font-family: DM Sans !important;
    font-size: 2.344vw !important;
    letter-spacing: -0.016vw !important;
    color: #424244;
    font-weight: 400 !important;
  }
  .architecture-url-text {
    font-family: DM Sans !important;
    color: rgb(255, 255, 255) !important;
    font-size: 2.604vw !important;
    font-weight: "bold" !important;
  }
  .architecture-subtitle {
    line-height: 1.5 !important;
    margin-left: 3.6969vw !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 426px) {
  .height-image {
    height: 70vw;
  }
  .architecture-title-font {
    font-family: DM Sans !important;
    font-size: 16px !important;
  }
  .architecture-subtitle-font {
    font-family: DM Sans !important;
    font-size: 14px !important;
    letter-spacing: -0.07px !important;
    color: #424244;
    font-weight: 400 !important;
  }
  .architecture-content {
    padding-left: 1.4583vw !important;
    padding-right: 1.4583vw !important;
    margin-top: 30px !important;
  }
  .architecture-url-text {
    font-family: DM Sans !important;
    color: rgb(255, 255, 255) !important;
    font-size: 13px !important;
    font-weight: "bold" !important;
  }
  .architecture-title {
    line-height: 1.5 !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .architecture-subtitle {
    line-height: 1.5 !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .architecture-url {
    line-height: 1.2 !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

@media screen and (max-width: 424px) {
  .height-image {
    height: 70vw;
  }
  .architecture-title-font {
    font-family: DM Sans !important;
    font-size: 3.756vw !important;
  }
  .architecture-subtitle-font {
    font-family: DM Sans !important;
    font-size: 3.286vw !important;
    letter-spacing: -0.016vw !important;
    color: #424244;
    font-weight: 400 !important;
  }
  .architecture-content {
    padding-left: 1.4583vw !important;
    padding-right: 1.4583vw !important;
    margin-top: 7.042vw !important;
  }
  .architecture-url-text {
    font-family: DM Sans !important;
    color: rgb(255, 255, 255) !important;
    font-size: 3.052vw !important;
    font-weight: "bold" !important;
  }
  .architecture-title {
    line-height: 1.5 !important;
    margin-left: 4.695vw !important;
    margin-right: 4.695vw !important;
  }
  .architecture-subtitle {
    line-height: 1.5 !important;
    margin-left: 4.695vw !important;
    margin-right: 4.695vw !important;
  }
  .architecture-url {
    line-height: 1.2 !important;
    margin-left: 4.695vw !important;
    margin-right: 4.695vw !important;
  }
}
</style>
