import i18n from "@/i18n";

const getters = {
  /* Get details of a site */
  getDetails: state => () => {
    if (state.data && state.data.site_details) {
      const locale = i18n.locale;

      const site_details = state.data.site_details.find(
        item => item.language_slug === locale
      );

      return site_details ? site_details : {};
    } else {
      return {};
    }
  },

  /* Get details.main_image_url of a site */
  getMainImage: (state, getters) => () => {
    return getters.getDetails().main_image_url
      ? `${process.env.VUE_APP_BASE_URL}${getters.getDetails().main_image_url}`
      : "";
  },

  /* Get details.title of a site */
  getTitle: (state, getters) => () => {
    return getters.getDetails().title ? getters.getDetails().title : "";
  },

  /* Get details.subtitle of a site */
  getSubtitle: (state, getters) => () => {
    return getters.getDetails().subtitle ? getters.getDetails().subtitle : "";
  },

  /* Get details.horizontal_text_position of a site */
  getHorizontalTextPosition: (state, getters) => () => {
    return getters.getDetails().horizontal_text_position
      ? getters.getDetails().horizontal_text_position
      : "";
  },

  /* Get details.concept_image_url of a site */
  getConceptImage: (state, getters) => () => {
    return getters.getDetails().concept_image_url
      ? `${process.env.VUE_APP_BASE_URL}${
          getters.getDetails().concept_image_url
        }`
      : "";
  },

  /* Get details.link_text of a site */
  getLinkText: (state, getters) => () => {
    return getters.getDetails().link_text ? getters.getDetails().link_text : "";
  },

  /* Get details.link_to_see_more of a site */
  getLinkToSeeMore: (state, getters) => () => {
    return getters.getDetails().link_to_see_more
      ? getters.getDetails().link_to_see_more
      : "";
  },

  /* Get details.section_order of a site */
  getSectionOrder: (state, getters) => () => {
    return getters.getDetails().section_order
      ? getters.getDetails().section_order
      : [];
  },

  /* Get site_text_color of a site */
  getSiteTextColor: state => () => {
    return state.data && state.data.site_text_color
      ? state.data.site_text_color
      : "rgb(255,255,255) ";
  },

  /* Get bottom_gap_color of a site */
  getBottomGapColor: state => () => {
    return state.data && state.data.bottom_gap_color
      ? state.data.bottom_gap_color + "!important"
      : "rgb(255,255,255)  !important";
  },

  /* Get name of a site */
  getName: state => () => {
    return state.data && state.data.name ? state.data.name : "";
  },

  /* Get attributes of a site */
  getSite: (state, getters) => () => {
    const site = {
      img: getters.getMainImage(),
      title: getters.getTitle(),
      subtitle: getters.getSubtitle(),
      alignText: getters.getHorizontalTextPosition(),
      color: getters.getSiteTextColor()
    };

    return site;
  },

  /* Get attributes of a concept */
  getConcept: (state, getters) => () => {
    const concept = {
      img: getters.getConceptImage(),
      seeMoreText: getters.getLinkText(),
      seeMoreUrl: getters.getLinkToSeeMore()
    };

    return concept;
  }
};

export default getters;
