import axios from "axios";

const actions = {
  /* Get all applications */
  readAll() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/applications`)
        .then(response => {
          const applications = response.data.data;
          resolve(applications);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {});
    });
  },

  /* Get application */
  readById({ commit }, idApplication) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);

      axios
        .get(`/api/applications`, {
          params: {
            application_id: idApplication
          }
        })
        .then(response => {
          const application = response.data.data;
          commit("setData", application);
          resolve(application);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    });
  }
};

export default actions;
