import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home/Index.vue";
import Application from "@/views/Application/Index.vue";
import Product from "@/views/Product/Index.vue";
/* import goTo from "vuetify/es5/services/goto"; */
Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/product/:id",
    component: Product,
  },
  {
    path: "/application/:id",
    name: "Application",
    component: Application,
  },
  {
    path: "/application/1",
    name: "Application1",
    component: Application,
  },
  {
    path: "/application/2",
    name: "Application2",
    component: Application,
  },
  {
    path: "/application/3",
    name: "Application3",
    component: Application,
  },
  {
    path: "/application/5",
    name: "Application5",
    component: Application,
  },
  {
    path: "/application/6",
    name: "Application6",
    component: Application,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About/Index.vue"),
  },
  {
    path: "/Application",
    name: "applications2",
    component: function() {
      return import(
        /* webpackChunkName: "application" */
        "@/views/Application/Index.vue"
      );
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return {
      selector: scrollTo,
      offset: { x: 0, y: 60 },
    };

    /* return goTo(scrollTo, {
      duration: 0,
      offset: 17
    }).catch(e => console.log(e)); */
  },
  routes,
});

export default router;
