const mutations = {
  setData(state, data) {
    state.data = data;
  },

  setLoading(state, loading) {
    state.loading = loading;
  }
};

export default mutations;
