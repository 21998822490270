import axios from "axios";

const actions = {
  /* Get all products */
  readAll() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/products`)
        .then(response => {
          const products = response.data.data;
          resolve(products);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {});
    });
  },

  /* Get product */
  readById({ commit }, idProduct) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);

      axios
        .get(`/api/products`, {
          params: {
            product_id: idProduct
          }
        })
        .then(response => {
          const product = response.data.data;
          commit("setData", product);
          resolve(product);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    });
  },

  /* Get product by application id */
  readByApplication({ commit }, applicationId) {
    return new Promise((resolve, reject) => {
      commit("setLoadingByApplication", true);
      axios
        .get(`/api/products`, {
          params: {
            application_id: applicationId
          }
        })
        .then(response => {
          const product = response.data.data;
          commit("setDataByApplication", product);
          resolve(product);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("setLoadingByApplication", false);
        });
    });
  }
};

export default actions;
