<template>
  <div>
    <v-row
      no-gutters
      :justify="alignText.content"
      data-aos="fade-in"
      data-aos-duration="1000"
      data-aos-delay="400"
    >
      <v-col md="7" sm="5" class="application-container" order="1">
        <div class="d-flex flex-column justify-center fill-height">
          <div
            v-if="title"
            :class="[alignText.text, 'title-app-font  transition-swing']"
            :style="{ color: titleTextColor }"
            v-text="title"
          ></div>

          <div
            v-if="subtitle"
            :class="[
              alignText.text,
              subtitleContentClass,
              'subtitle-app-font transition-swing',
            ]"
            :style="{ color: subtitleTextColor }"
            v-text="subtitle"
          ></div>

          <div
            v-if="url"
            :class="[
              alignText.text,
              urlContentClass,
              'application-url-line-height transition-swing',
            ]"
          >
            <a
              :class="['app-url-font text-decoration-none transition-swing']"
              :style="{ color: urlTextColor }"
              @click="goTo(url)"
            >
              <div class="application-url-line" v-text="urlText" />
            </a>
          </div>
        </div>
      </v-col>

      <v-col md="5" sm="7" class="white" order="2">
        <div class="d-flex flex-column align-center fill-height">
          <v-img
            class="image-style"
            :src="img"
            :max-height="$vuetify.breakpoint.width * 0.4"
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getAlignment } from "@/utils/-.js";

export default {
  name: "AppComponent",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    img() {
      return this.data.img ? this.data.img : "";
    },
    alignText() {
      const alignReq = this.data.alignText ? this.data.alignText : undefined;
      const align = getAlignment(alignReq);

      return {
        content: align,
        text: `text-${align}`,
      };
    },
    colOrder() {
      const alignReq = this.data.alignText ? this.data.alignText : undefined;
      const align = getAlignment(alignReq);

      switch (align) {
        case "start":
          return {
            text: 1,
            image: 2,
          };
        case "end":
          return {
            text: 2,
            image: 1,
          };

        default:
          return {
            text: 1,
            image: 2,
          };
      }
    },
    title() {
      return this.data.title ? this.data.title : "";
    },
    titleTextColor() {
      return this.data.titleTextColor;
    },
    subtitle() {
      return this.data.subtitle ? this.data.subtitle : "";
    },
    subtitleTextColor() {
      return this.data.subtitleTextColor;
    },
    subtitleContentClass() {
      return this.title ? "application-subtitle" : null;
    },
    urlText() {
      return this.data.urlText ? this.data.urlText : "Ver más";
    },
    urlTextColor() {
      return this.data.urlTextColor;
    },
    url() {
      return this.data.url ? this.data.url : "";
    },
    urlContentClass() {
      return this.title || this.subtitle ? "application-url" : null;
    },
    textColor() {
      return this.data.color
        ? this.data.color + "!important"
        : "rgb(255, 255, 255) !important";
    },
  },
  methods: {
    /** Método para navegar entre las vistas */
    goTo(path) {
      this.$router
        .push(`application/${path}`)
        .then(() => {})
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
.image-style {
  background-size: contain;
}
.app-url-font {
  font-family: DM Sans !important;
  font-size: 18px !important;
  letter-spacing: -0.07px !important;
  font-weight: 500 !important;
}
.title-app-font {
  font-size: 54px;
  font-weight: 400 !important;
  line-height: 65px;
  font-family: Dm Sans !important;
}

.subtitle-app-font {
  font-size: 18px;
  font-weight: 400 !important;

  line-height: 27px;
  font-family: Dm Sans !important;
}
.application-container {
  padding-top: 3.4994vw;
  padding-bottom: 3.4994vw;
  padding-left: 6.5999vw;
  padding-right: 6.5999vw;
}

.application-title {
  line-height: 1.2 !important;
}

.application-title-text {
  font-family: DM Sans !important;
  font-size: 3.5156vw !important;
  font-weight: 400 !important;
}

.application-subtitle {
  padding-top: 15px;
}

.application-subtitle-line-height {
  line-height: 1.5 !important;
}

.application-subtitle-text {
  font-family: DM Sans !important;
  font-size: 1.1719vw !important;
  letter-spacing: -0.07px !important;
}

.application-url {
  padding-top: 3.7907vw;
}

.application-url-line-height {
  line-height: 1.2 !important;
}

.application-url-text {
  font-family: DM Sans !important;
  font-size: 1.1719vw !important;
  letter-spacing: -0.07px !important;
  font-weight: 500 !important;
}

.application-url-line {
  display: inline-block;
  border-bottom: 0.1045vw solid #f41f1f;
  padding-bottom: 0.5208vw !important;
}

@media screen and (max-width: 1600px) {
  .title-app-font {
    font-size: 3.5156vw !important;
    font-weight: 400 !important;
    line-height: 4.063vw !important;
    font-family: Dm Sans !important;
  }

  .subtitle-app-font {
    font-size: 1.1719vw !important;
    font-weight: 400 !important;

    line-height: 1.688vw !important;
    font-family: Dm Sans !important;
  }
  .app-url-font {
    font-family: DM Sans !important;
    font-size: 1.125vw !important;
    letter-spacing: -0.07px !important;
    font-weight: 500 !important;
  }
  .application-subtitle {
    padding-top: 0.938vw;
  }
}

@media screen and (max-width: 769px) {
  .title-app-font {
    font-size: 32px !important;
    font-weight: 400 !important;
    line-height: 65px;
    font-family: Dm Sans !important;
  }

  .subtitle-app-font {
    font-size: 14px !important;
    font-weight: 400 !important;

    line-height: 20px !important;
    font-family: Dm Sans !important;
  }
  .app-url-font {
    font-family: DM Sans !important;
    font-size: 12px !important;
    letter-spacing: -0.07px !important;
    font-weight: 500 !important;
  }
  .application-subtitle {
    padding-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .title-app-font {
    font-size: 4.167vw !important;
    font-weight: 400 !important;
    line-height: 2.604vw !important;
    font-family: Dm Sans !important;
  }

  .subtitle-app-font {
    font-size: 1.563vw !important;
    font-weight: 400 !important;

    line-height: 2.604vw !important;
    font-family: Dm Sans !important;
  }
  .app-url-font {
    font-family: DM Sans !important;
    font-size: 1.563vw !important;
    letter-spacing: -0.009vw !important;
    font-weight: 500 !important;
  }
  .application-subtitle {
    padding-top: 0.938vw;
  }
}

@media screen and (max-width: 426px) {
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .image-style {
    background-size: cover;
  }
  .title-app-font {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    font-family: Dm Sans !important;
  }

  .subtitle-app-font {
    font-size: 11px !important;
    font-weight: 400 !important;

    line-height: 12px !important;
    font-family: Dm Sans !important;
  }
  .application-url {
    padding-top: 10px;
  }
  .app-url-font {
    font-family: DM Sans !important;
    font-size: 11px !important;
    letter-spacing: -0.009vw !important;
    font-weight: 500 !important;
  }
  .application-container {
    padding-top: 1vw;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
  .application-subtitle {
    padding-top: 15px;
  }
}

@media screen and (max-width: 424px) {
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .image-style {
    background-size: cover;
  }
  .title-app-font {
    font-size: 4.225vw !important;
    font-weight: 400 !important;
    line-height: 4.225vw !important;
    font-family: Dm Sans !important;
  }

  .subtitle-app-font {
    font-size: 2.582vw !important;
    font-weight: 400 !important;

    line-height: 2.817vw !important;
    font-family: Dm Sans !important;
  }
  .application-url {
    padding-top: 2.347vw;
  }
  .app-url-font {
    font-family: DM Sans !important;
    font-size: 2.582vw !important;
    letter-spacing: -0.009vw !important;
    font-weight: 500 !important;
  }
  .application-container {
    padding-top: 1vw;
    padding-bottom: 0;
    padding-left: 3.521vw;
    padding-right: 3.521vw;
  }
}
@media only screen and (max-width: 769px) and (min-width: 426px) {
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
}
</style>
