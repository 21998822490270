<template>
  <v-row no-gutters class="ma-0 pa-0" align="center" justify="center">
    <v-progress-linear v-if="type === 'linear'" indeterminate color="#4368ad"></v-progress-linear>
    <v-progress-circular v-else indeterminate color="#4368ad"></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  name: "LoadingComponent",
  props: {
    type: {
      type: String,
      default: "linear",
      validator: value => ["linear", "circular"].indexOf(value) !== -1
    }
  }
};
</script>
