<template>
  <!-- If loading and application exist -->
  <v-main class="pa-0" v-if="!loading && application">
    <section id="TopImageComponent">
      <TopImageComponent :data="applicationDetails"></TopImageComponent>
    </section>

    <section id="ArchitectureComponent">
      <ArchitectureComponent :data="applicationDetails.architecture"></ArchitectureComponent>
    </section>

    <section id="RelatedComponent">
      <!-- If loading related products by application -->
      <LoadingComponent v-if="loadingByApplication" type="linear"/>
      <Related
        v-if="getApplicationShowProductSection() && !loadingByApplication"
        :data="getApplicationProducts()"
      />
    </section>

    <section id="ApplicationLastSpace" :style="{ backgroundColor: getApplicationLastSpaceColor()}">
      <v-col class="last-space"></v-col>
    </section>
  </v-main>

  <v-main class="pa-0" v-else>
    <!-- If loading application -->
    <LoadingComponent v-if="loading" type="linear"/>
  </v-main>
</template>

<script>
import TopImageComponent from "@/components/Application/TopImageComponent.vue";
import ArchitectureComponent from "@/components/Application/ArchitectureComponent.vue";
import Related from "@/components/Application/RelatedProd.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Application",
  components: {
    TopImageComponent,
    ArchitectureComponent,
    Related,
    LoadingComponent
  },
  data: () => ({}),

  computed: {
    ...mapState("application", {
      application: state => state.data,
      loading: state => state.loading
    }),

    ...mapState("product", {
      products: state => state.dataByApplication,
      loadingByApplication: state => state.loadingByApplication
    }),

    ...mapGetters("application", {
      getApplicationMainImageUrl: "getMainImageUrl",
      getApplicationTitle: "getTitle",
      getApplicationSubtitle: "getSubtitle",
      getApplicationAlignTextInImage: "getAlignTextInImage",
      getApplicationArchitectureTitle: "getArchitectureTitle",
      getApplicationArchitectureDescription: "getArchitectureDescription",
      getApplicationLinkText: "getLinkText",
      getApplicationLinkToSeeMore: "getLinkToSeeMore",
      getApplicationArchitectureImageUrl: "getArchitectureImageUrl",
      getApplicationShowProductSection: "getShowProductSection",
      getApplicationProducts: "getProducts",
      getApplicationUseImagesPerLanguage: "getUseImagesPerLanguage",
      getApplicationLastSpaceColor: "getLastSpaceColor"
    }),

    applicationDetails() {
      const application = {
        id: "App1",
        img: this.getApplicationMainImageUrl(),
        title: this.getApplicationTitle(),
        subtitle: this.getApplicationSubtitle(),
        alignText: this.getApplicationAlignTextInImage(),
        color: "rgb(255, 255, 255)",
        architecture: {
          title: this.getApplicationArchitectureTitle(),
          subtitle: this.getApplicationArchitectureDescription(),
          urlText: this.getApplicationLinkText(),
          url: this.getApplicationLinkToSeeMore(),
          alignText: "start",
          img: this.getApplicationArchitectureImageUrl()
        },
        showProductSection: this.getApplicationShowProductSection(),
        useImagesPerLanguage: this.getApplicationUseImagesPerLanguage()
      };

      return application;
    }
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  created() {
    this.read();
  },

  methods: {
    read() {
      const idApplication = this.$route.params.id;
      this.$store
        .dispatch("application/readById", idApplication)
        .then(application => {
          this.$store
            .dispatch("product/readByApplication", application.application_id)
            .then(() => {})
            .catch(() => {});
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.last-space {
  padding: 0.625vw;
}
</style>
