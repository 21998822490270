var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"architecture-container",attrs:{"no-gutters":"","justify":_vm.alignText.content,"data-aos":"fade-in","data-aos-duration":"1000","data-aos-delay":"400"}},[_c('v-col',{staticClass:"architecture-content",attrs:{"cols":_vm.numCol,"order":_vm.orderText}},[(_vm.title)?_c('div',{class:[
          'text-left',
          'architecture-title architecture-title-font transition-swing' ],style:({ color: _vm.textColor }),domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),(_vm.subtitle.length)?_c('div',{class:[
          _vm.alignText.text,
          _vm.subtitleContentClass,
          'architecture-subtitle architecture-subtitle-font transition-swing' ]},_vm._l((_vm.subtitle),function(subtitleItem,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(subtitleItem)+" "),_c('br')])}),0):_vm._e(),(!_vm.url)?_c('div',{class:[
          'text-left',
          _vm.urlContentClass,
          'architecture-url transition-swing' ]},[_c('a',{class:[
            'architecture-url-text text-decoration-none transition-swing' ],attrs:{"href":_vm.url}},[_c('div',{staticClass:"architecture-url-line",style:({ color: _vm.textColor }),domProps:{"textContent":_vm._s(_vm.urlText)}})])]):_vm._e()]),_c('v-col',{staticStyle:{"align-self":"center"},attrs:{"cols":_vm.numCol,"order":_vm.orderImage}},[_c('v-img',{staticClass:" align-center height-image",attrs:{"src":_vm.img,"contain":"","max-height":_vm.maxHeight}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }