import i18n from "@/i18n";

const getters = {
  /* Get section_id of a section */
  getSectionId: state => index => {
    return state.data && state.data[index] && state.data[index].section_id
      ? state.data[index].section_id
      : 0;
  },

  /* Get site_name of a section */
  getSiteName: state => index => {
    return state.data && state.data[index] && state.data[index].site_name
      ? state.data.site_name
      : "";
  },

  /* Get nickname of a section */
  getNickname: state => index => {
    return state.data && state.data[index] && state.data[index].nickname
      ? state.data.nickname
      : "";
  },

  /* Get related_application_id of a section */
  getRelatedApplicationId: state => index => {
    return state.data &&
      state.data[index] &&
      state.data[index].related_application_id
      ? state.data[index].related_application_id
      : "app";
  },

  /* Get details of a section */
  getDetails: state => index => {
    const locale = i18n.locale;

    if (state.data && state.data[index] && state.data[index].section_details) {
      const sectionDetails = state.data[index].section_details.find(
        item => item.language_slug === locale
      );

      return sectionDetails ? sectionDetails : {};
    } else {
      return {};
    }
  },

  /* Get details.image_url of a section */
  getImageUrl: (state, getters) => index => {
    return getters.getDetails(index).image_url
      ? `${process.env.VUE_APP_BASE_URL}${getters.getDetails(index).image_url}`
      : "";
  },

  /* Get details.title of a section */
  getTitle: (state, getters) => index => {
    return getters.getDetails(index).title
      ? getters.getDetails(index).title
      : "";
  },

  /* Get details.title_text_color of a section */
  getTitleTextColor: (state, getters) => index => {
    return getters.getDetails(index).title_text_color
      ? getters.getDetails(index).title_text_color
      : "#113353";
  },

  /* Get details.subtitle of a section */
  getSubtitle: (state, getters) => index => {
    return getters.getDetails(index).subtitle
      ? getters.getDetails(index).subtitle
      : "";
  },

  /* Get details.subtitle_text_color of a section */
  getSubtitleTextColor: (state, getters) => index => {
    return getters.getDetails(index).subtitle_text_color
      ? getters.getDetails(index).subtitle_text_color
      : "#757575";
  },

  /* Get details.link_text of a section */
  getLinkText: (state, getters) => index => {
    return getters.getDetails(index).link_text
      ? getters.getDetails(index).link_text
      : "";
  },

  /* Get details.link_text_color of a section */
  getLinkTextColor: (state, getters) => index => {
    return getters.getDetails(index).link_text_color
      ? getters.getDetails(index).link_text_color
      : "#113353";
  },

  /* Get details.link_to_see_more of a section */
  getLinkToSeeMore: (state, getters) => index => {
    return getters.getDetails(index).link_to_see_more
      ? getters.getDetails(index).link_to_see_more
      : "";
  },

  /* Get details.use_image_per_language of a section */
  getUseImagePerLanguage: (state, getters) => index => {
    return getters.getDetails(index).use_image_per_language
      ? getters.getDetails(index).use_image_per_language
      : "true";
  },

  /* Get details.align_text of a section */
  getAlignText: (state, getters) => index => {
    return getters.getDetails(index).align_text
      ? getters.getDetails(index).align_text
      : "";
  },

  /* Get details.background_color of a section */
  getBackgroundColor: (state, getters) => index => {
    return getters.getDetails(index).background_color
      ? getters.getDetails(index).background_color
      : "rgb(15 51 82)";
  },

  /* Get details.is_visible of a section */
  getIsVisible: (state, getters) => index => {
    return getters.getDetails(index).is_visible
      ? getters.getDetails(index).is_visible
      : false;
  },

  /* Get sections */
  getSections: (state, getters, rootState, rootGetters) => () => {
    if (state.data && state.data.length) {
      const sections = state.data;
      const sectionOrder = rootGetters["site/getSectionOrder"]();

      const sectionsToShow = sections.map((section, index) => {
        const { section_id } = section;

        /* Find position */
        const position = sectionOrder.findIndex(
          sectionId => sectionId === section_id
        );

        const sectionToShow = {
          section_id,
          section_details: {
            img: getters.getImageUrl(index),
            title: getters.getTitle(index),
            titleTextColor: getters.getTitleTextColor(index),
            subtitle: getters.getSubtitle(index),
            subtitleTextColor: getters.getSubtitleTextColor(index),
            alignText: getters.getAlignText(index),
            urlText: getters.getLinkText(index),
            urlTextColor: getters.getLinkTextColor(index),
            url: getters.getRelatedApplicationId(index),
            backgroundColor: getters.getBackgroundColor(index),
            visible: getters.getIsVisible(index),
            color: "#757575",
            position: position
          }
        };

        return sectionToShow;
      });

      /* Filter by visibility */
      const filteredSectionToShow = sectionsToShow.filter(
        item => item.section_details.visible
      );

      /* Order asc */
      const orderedSectionToShow = filteredSectionToShow.sort(
        (a, b) => a.section_details.position - b.section_details.position
      );

      return orderedSectionToShow;
    } else {
      return [];
    }
  }
};

export default getters;
