<template>
  <div>
    <!-- Image -->
    <v-img :src="img" :max-height="maxHeight" class="align-start height-image">
      <!-- Placeholder loader on image -->
      <template v-slot:placeholder>
        <LoadingComponent type="linear" />
      </template>

      <!-- Text on image -->
      <v-row
        no-gutters
        class="inside-image-container"
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <v-col cols="12">
          <!-- Title -->
          <v-row no-gutters :justify="alignText.content">
            <v-col cols="auto">
              <div
                class="inside-image-title-font transition-swing"
                :style="{ color: textColor }"
                v-text="title"
              />
            </v-col>
          </v-row>

          <!-- Subtitle -->
          <v-row
            no-gutters
            :class="[alignText.padding]"
            :justify="alignText.content"
          >
            <v-col cols="auto">
              <div
                :class="[
                  alignText.text,
                  'inside-image-subtitle-font transition-swing',
                ]"
                :style="{ color: textColor }"
                v-text="subtitle"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-img>
  </div>
</template>

<script>
import { getAlignment } from "@/utils/-.js";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  name: "TopImageComponent",
  components: {
    LoadingComponent,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    img() {
      return this.data.img ? this.data.img : "";
    },
    title() {
      return this.data.title ? this.data.title : "";
    },
    subtitle() {
      return this.data.subtitle ? this.data.subtitle : "";
    },
    alignText() {
      const alignReq = this.data.alignText ? this.data.alignText : undefined;
      const align = getAlignment(alignReq);

      return {
        content: align,
        text: `text-${align}`,
        padding: `inside-image-subtitle-container-${align}`,
      };
    },
    textColor() {
      return this.data.color
        ? this.data.color + "!important"
        : "rgb(15, 51, 82) !important";
    },
    maxHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return null;
        case "sm":
          return this.$vuetify.breakpoint.width * 0.45;
        case "md":
          return this.$vuetify.breakpoint.width * 0.45;
        case "lg":
          return this.$vuetify.breakpoint.width * 0.45;
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
.inside-image-title-font {
  font-size: 58px;
  font-weight: 400 !important;

  font-family: Dm Sans !important;
  letter-spacing: 0.0125em !important;
}

.inside-image-subtitle-font {
  font-size: 20px;
  font-weight: 400 !important;
  letter-spacing: -0.08px !important;
  line-height: 30px;
  font-family: Dm Sans !important;
}

.inside-image-container {
  padding-top: 62px !important;
  padding-left: 80px !important;
  padding-right: 80px !important;
  line-height: 1.2;
}

.inside-image-subtitle-container-start {
  padding-top: 0.5vw !important;
  padding-right: 31vw !important;
}

.inside-image-subtitle-container-center {
  padding-top: 0.5vw !important;
}

.inside-image-subtitle-container-end {
  padding-top: 0.5vw !important;
  padding-left: 31vw !important;
}

.height-image {
  height: 100%;
}
@media screen and (max-width: 1600px) {
  .inside-image-title-font {
    font-size: 3.625vw;
    font-weight: 400 !important;

    font-family: Dm Sans !important;
    letter-spacing: 0.0225em !important;
  }

  .inside-image-subtitle-font {
    font-size: 1.25vw !important;
    font-weight: 400 !important;
    letter-spacing: -0.08px !important;
    line-height: 1.875vw !important;
    font-family: Dm Sans !important;
  }
  .inside-image-container {
    padding-top: 3.9vw !important;
    padding-left: 5vw !important;
    padding-right: 5vw !important;
    line-height: 1.2;
  }
}
@media screen and (max-width: 769px) {
  .inside-image-title-font {
    font-family: Dm Sans !important;
    font-size: 36px !important;
    font-weight: 400 !important;
    letter-spacing: 0.0225em !important;
  }

  .inside-image-subtitle-font {
    font-family: Dm Sans !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    letter-spacing: -0.08px !important;
    line-height: 17px !important;
  }
  .inside-image-subtitle-container-start {
    padding-top: 0vw !important;
    padding-right: 150px !important;
  }
}
@media screen and (max-width: 767px) {
  .inside-image-title-font {
    font-family: Dm Sans !important;
    font-size: 4.688vw !important;
    font-weight: 400 !important;
    letter-spacing: 0.0225em !important;
  }

  .inside-image-subtitle-font {
    font-family: Dm Sans !important;
    font-size: 1.693vw !important;
    font-weight: 400 !important;
    letter-spacing: -0.01vw !important;
    line-height: 2.214vw !important;
  }
  .inside-image-subtitle-container-start {
    padding-top: 0vw !important;
    padding-right: 19.531vw !important;
  }
}

@media screen and (max-width: 426px) {
  .height-image {
    height: 60vw;
  }
  .inside-image-title-font {
    font-family: Dm Sans !important;
    font-size: 22px !important;
    font-weight: 400 !important;

    letter-spacing: 0.0225em !important;
  }
  .inside-image-subtitle-font {
    font-family: Dm Sans !important;
    font-size: 11px !important;
    font-weight: 400 !important;
    letter-spacing: -0.01vw !important;
    line-height: 12px !important;
  }
  .inside-image-subtitle-container-start {
    padding-top: 4px !important;
    padding-right: 30px !important;
  }
}
@media screen and (max-width: 424px) {
  .height-image {
    height: 60vw;
  }
  .inside-image-title-font {
    font-family: Dm Sans !important;
    font-size: 5.164vw !important;
    font-weight: 400 !important;

    letter-spacing: 0.0225em !important;
  }
  .inside-image-subtitle-font {
    font-family: Dm Sans !important;
    font-size: 2.582vw !important;
    font-weight: 400 !important;
    letter-spacing: -0.01vw !important;
    line-height: 2.817vw !important;
  }
  .inside-image-subtitle-container-start {
    padding-top: 0.939vw !important;
    padding-right: 7.042vw !important;
  }
}
</style>
