import Vue from "vue";
import axios from "axios";
import Vuetify from "vuetify/lib";
import EvaIcons from "vue-eva-icons";
import es from "vuetify/es5/locale/es";
import en from "vuetify/es5/locale/en";
import store from "./store";
import router from "./router";
import i18n from "./i18n";
import App from "./App.vue";

console.log(`${process.env.VUE_APP_TITLE}`);

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common["Authorization"] = "Bearer sicom";

Vue.config.productionTip = false;
Vue.use(Vuetify);
Vue.use(EvaIcons);

const opts = {
  lang: {
    locales: { es, en },
    current: "es",
  },
  icons: {
    iconfont: "mdi",
  },

  breakpoint: {
    thresholds: {
      xs: 427,
      sm: 769,
      md: 1600,
      lg: 1920,
    },
    scrollBarWidth: 24,
  },
};

new Vue({
  vuetify: new Vuetify(opts),
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
