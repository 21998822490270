import axios from "axios";

const actions = {
  /* Get sections by site */
  readBySite({ commit }, siteName) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      axios
        .get(`/api/sections`)
        .then(response => {
          const sections = response.data.data.filter(
            item => item.site_name === siteName
          );
          commit("setData", sections);
          resolve(sections);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit("setLoading", false);
        });
    });
  }
};

export default actions;
