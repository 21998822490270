<template>
  <v-row v-if="!loading" class="details product-container">
    <v-col xs="12" md="6" class="mb-md-6 product-carousell-container">
      <v-row
        no-gutters
        class="mr-md-10 product-carousell-content"
        v-if="!modelSelected || !modelSelected.image_resources"
      >
        <v-col cols="12">
          <span class="mt-5 product-spec-info-items-text">{{
            $t("product.image.notFound")
          }}</span>
        </v-col>
      </v-row>

      <v-row no-gutters class="mr-md-10 product-carousell-content" v-else>
        <v-col cols="12" class="product-carousell-image-content">
          <v-img
            class="mt-7 shrink product-carousell-image"
            contain
            :src="modelSelected.image_resources[selected].image_url"
          />
        </v-col>

        <v-col cols="12" class="my-5 product-carousell-items">
          <vueper-slides
            ref="carousell"
            class="no-shadow product-carousell-items-slides"
            :visible-slides="3"
            :slide-ratio="1 / 4"
            :gap="3"
            :dragging-distance="200"
            :arrowOutside="true"
            :bullets="false"
            @slide="setImageIndex($event.currentSlide)"
          >
            <template v-slot:arrow-left>
              <v-card
                class="effect product-carousell-items-arrow"
                @click="prevSelected()"
              >
                <v-icon class="product-carousell-items-arrow-icon"
                  >mdi-chevron-left</v-icon
                >
              </v-card>
            </template>

            <template v-slot:arrow-right>
              <v-card
                class="effect product-carousell-items-arrow"
                @click="nextSelected()"
              >
                <v-icon class="product-carousell-items-arrow-icon"
                  >mdi-chevron-right</v-icon
                >
              </v-card>
            </template>

            <vueper-slide
              :class="[
                { wrapImg: index === selected },
                'product-carousell-items-slide',
              ]"
              v-for="(item, index) in modelSelected.image_resources"
              :key="index"
              :image="item.image_url"
              @click.native="changeImg(index)"
            />
          </vueper-slides>

          <v-col class="mt-6">
            <strong>
              <span class="product-carousell-content-link" @click="click()">{{
                $t("product.order.url")
              }}</span>
            </strong>
          </v-col>
        </v-col>
      </v-row>
    </v-col>

    <v-col xs="12" md="6" class="mb-6 product-spec-container">
      <v-row no-gutters class="ml-md-10">
        <!-- Name and Tags Section -->
        <v-col
          cols="12"
          class="mb-3 mt-5 product-spec-title-content"
          v-if="getProductName()"
        >
          <v-row no-gutters>
            <v-col cols="auto">
              <span class="product-spec-title-text">{{
                getProductName()
              }}</span>
            </v-col>

            <!-- Tags Hidden -->
            <template v-if="false">
              <v-col
                cols="auto"
                class="mr-2 mb-2"
                v-for="(tagNickname, i) in getProductTagsNickname()"
                :key="i"
              >
                <v-chip color="#113353" label text-color="white">{{
                  tagNickname
                }}</v-chip>
              </v-col>
            </template>
          </v-row>
        </v-col>

        <!-- Breadcrumbs Section -->
        <v-col cols="12" class="product-spec-breadcrumbs-content">
          <v-breadcrumbs
            class="product-spec-breadcrumbs"
            :items="breadcrumbsItems"
            large
          >
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                exact
                :to="item.href"
                :disabled="item.disabled"
                >{{ item.text }}</v-breadcrumbs-item
              >
            </template>

            <template v-slot:divider>
              <v-icon color="#295AA6">mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-col>

        <!-- Description Section -->
        <v-col
          cols="12"
          class="mb-20 mt-10 mb-sm-6 bdescripcion product-spec-info-padding product-spec-info-justify"
          v-if="getProductShortDescription()"
        >
          <span class="product-spec-subtitle-text">{{
            getProductShortDescription()
          }}</span>
        </v-col>

        <v-col
          cols="12"
          class="mb-8 descripcion product-spec-info-padding product-spec-info-justify"
          v-if="getProductLongDescription().length"
        >
          <span
            class="product-spec-body-text"
            v-for="(description, i) in getProductLongDescription()"
            :key="i"
          >
            {{ description }}
            <br />
          </span>
        </v-col>

        <!-- Applications Section -->
        <v-col cols="12" class="mb-6 product-spec-info-padding">
          <v-row no-gutters>
            <v-col cols="12" class="mb-3">
              <span class="product-spec-info-section-title">{{
                $t("product.applications.title")
              }}</span>
            </v-col>
          </v-row>

          <!-- Applications empty -->
          <v-row no-gutters v-if="!getProductUses().length" class="mb-4">
            <v-col cols="12">
              <span class="mt-5 product-spec-info-items-text">{{
                $t("product.applications.notFound")
              }}</span>
            </v-col>
          </v-row>

          <v-row
            v-else
            no-gutters
            v-for="(item, i) in getProductUses()"
            :key="i"
            :class="[i === getProductUses().length - 1 ? 'mb-4' : null]"
          >
            <v-col cols="1" class="mr-2 mb-2">
              <v-img :src="item.use_details.icon_url"></v-img>
            </v-col>

            <v-col cols="8" class="my-2">
              <span class="mt-5 product-spec-info-items-text">{{
                item.use_details.name
              }}</span>
            </v-col>
          </v-row>
        </v-col>

        <!-- Features Section -->
        <v-col cols="12" class="mb-6 product-spec-info-padding">
          <v-row no-gutters>
            <v-col cols="12" class="mb-3">
              <span class="product-spec-info-section-title">{{
                $t("product.features.title")
              }}</span>
            </v-col>

            <!-- Models empty -->
            <v-row
              no-gutters
              v-if="
                !getProductModels().length ||
                  (getProductModels().length === 1 &&
                    !getProductModels()[0].characteristics.length)
              "
            >
              <v-col cols="12">
                <span class="mt-5 product-spec-info-items-text">{{
                  $t("product.features.notFound")
                }}</span>
              </v-col>
            </v-row>

            <v-col cols="12" v-else>
              <!-- Select Product Model 1 -->
              <v-row
                no-gutters
                v-if="getProductModels().length === 1"
                class="product-spec-feature-select-content"
                align="center"
              >
                <v-col cols="6">
                  <span class="product-spec-info-items-text">{{
                    $t("product.model.title")
                  }}</span>
                </v-col>

                <v-col cols="6">
                  <span class="product-spec-feature-model-text">{{
                    getProductModels()[0].name ? getProductModels()[0].name : ""
                  }}</span>
                </v-col>
              </v-row>

              <!-- Select Product Model n -->
              <v-row
                no-gutters
                v-else
                class="product-spec-feature-select-content"
                align="center"
              >
                <v-col cols="auto" class="mr-4">
                  <span class="product-spec-info-items-text">{{
                    $t("product.model.title")
                  }}</span>
                </v-col>

                <v-col cols="4">
                  <v-select
                    v-model="modelSelected"
                    :items="getProductModels()"
                    class="rounded-lg"
                    item-text="name"
                    item-value="id"
                    hide-details="true"
                    solo
                    color="red"
                    return-object
                  >
                    <template v-slot:append>
                      <v-icon color="#777777">mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-divider v-if="getProductModels().length"></v-divider>

              <v-row
                no-gutters
                v-for="(item, i) in modelSelected.characteristics"
                :key="i"
              >
                <v-col
                  cols="6"
                  :class="[
                    i !== modelSelected.characteristics.length - 1
                      ? 'product-spec-feature-items'
                      : 'product-spec-feature-items-last',
                  ]"
                >
                  <span class="product-spec-info-items-text">{{
                    item.characteristic_details.name
                  }}</span>
                </v-col>

                <v-col
                  cols="6"
                  :class="[
                    i !== modelSelected.characteristics.length - 1
                      ? 'product-spec-feature-items'
                      : 'product-spec-feature-items-last',
                  ]"
                >
                  <span class="product-spec-feature-items-value-text">{{
                    item.characteristic_details.value
                  }}</span>
                </v-col>

                <v-col
                  v-if="i !== modelSelected.characteristics.length - 1"
                  cols="12"
                >
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="product-spec-info-padding">
          <v-row no-gutters>
            <v-col cols="12" class="mb-3">
              <span class="product-spec-info-section-title">{{
                $t("product.documents.title")
              }}</span>
            </v-col>

            <!-- Documents empty -->
            <v-col
              cols="auto"
              class="mb-3"
              v-if="!getProductDocuments().length"
            >
              <span class="mt-5 product-spec-info-items-text">{{
                $t("product.documents.notFound")
              }}</span>
            </v-col>

            <v-col
              v-for="(document, index) in getProductDocuments()"
              :key="document.document_id"
              cols="auto"
              class="mr-4 mb-3"
            >
              <v-btn
                class="product-spec-doc-button"
                :color="!index % 2 ? '#002050' : '#3b68b2'"
                v-bind="size"
                depressed
                block
                :loading="
                  loadingDownloadDoc.state && loadingDownloadDoc.index === index
                "
                @click="downloadDocument(document, index)"
              >
                <v-icon class="pr-5">mdi-pdf-box</v-icon>
                {{ document.document_type.document_type_details.name }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <!--<v-col cols="3" class="mb-6 mt-6" style="height: auto;background-color:white;text-align:center;">
            <span>Seccion Compra</span>
    </v-col>-->
  </v-row>
  <!-- If loading product -->
  <LoadingComponent v-else type="linear" />
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import LoadingComponent from "@/components/LoadingComponent.vue";
import "vueperslides/dist/vueperslides.css";
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Product",
  components: { VueperSlides, VueperSlide, LoadingComponent },
  data: function() {
    return {
      selected: 0,
      modelSelected: null,
      loadingDownloadDoc: {
        state: false,
        index: undefined,
      },
    };
  },

  computed: {
    ...mapState("product", {
      product: (state) => state.data,
      loading: (state) => state.loading,
    }),
    size() {
      const size = { xs: "small", sm: "large", lg: "x-large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },

    ...mapGetters("product", {
      getProductName: "getName",
      getProductTagsNickname: "getTagsNickname",
      getProductShortDescription: "getShortDescription",
      getProductLongDescription: "getLongDescription",
      getProductUses: "getUses",
      getProductModels: "getModels",
      getProductDocuments: "getDocuments",
    }),

    breadcrumbsItems() {
      const items = [
        {
          text: this.$i18n.t("product.breadcrumbs.items.home"),
          disabled: false,
          href: "/home",
        },
        {
          text: this.$i18n.t("product.breadcrumbs.items.products"),
          disabled: true,
          href: "breadcrumbs_link_1",
        },
        {
          text: this.getProductName(),
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ];
      return items;
    },

    locale() {
      return this.$i18n.locale;
    },
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  created() {
    this.read();
  },

  methods: {
    read() {
      const idProduct = this.$route.params.id;
      this.$store
        .dispatch("product/readById", idProduct)
        .then(() => {
          this.modelSelected = this.getProductModels()[0];
        })
        .catch(() => {});
    },

    click() {
      console.log("click");
    },

    setImageIndex(currentSlide) {
      this.selected = currentSlide.index;
    },

    changeImg(i) {
      this.$refs.carousell.goToSlide(i);
      /*  if (i > this.selected) {
        for (let j = 0; j < i - this.selected; j++) {
          this.$refs.carousell.next();
        }
        this.selected = i;
      } else if (i < this.selected) {
        for (let j = 0; j < this.selected - i; j++) {
          this.$refs.carousell.previous();
        }
        this.selected = i;
      } */
    },

    prevSelected() {
      if (this.selected === 0) {
        this.selected = this.modelSelected.image_resources.length - 1;
      } else if (this.selected > 0) {
        this.selected = this.selected - 1;
      }
    },

    nextSelected() {
      if (this.selected <= this.modelSelected.image_resources.length - 1) {
        this.selected = this.selected + 1;
      }
      if (this.selected === this.modelSelected.image_resources.length) {
        this.selected = 0;
      }
    },

    downloadDocument(item, index) {
      this.loadingDownloadDoc = {
        state: true,
        index: index,
      };
      axios
        .get(`${item.path}`, { responseType: "blob" })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", item.name);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingDownloadDoc = {
            state: false,
            index: undefined,
          };
        });
    },
  },
  watch: {
    modelSelected(newVal) {
      if (newVal.image_resources.length) {
        this.selected = 0;
        this.$refs.carousell ? this.$refs.carousell.goToSlide(0) : null;
      }
    },
    locale() {
      this.modelSelected = this.getProductModels().find(
        (model) => model.model_id === this.modelSelected.model_id
      );
    },
  },
};
</script>

<style>
.product-container {
  background-color: rgb(248, 248, 248);
  margin-left: 6.95vw !important;
  margin-right: 6.95vw !important;
}

.product-carousell-container {
  text-align: center;
  background-color: white;
  padding: 0px !important;
}

.product-carousell-content {
  position: sticky;
  top: 90px;
}

.product-carousell-image-content {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-carousell-image {
  width: 500px;
  height: 400px;
  border-style: solid;
  border-width: thin;
  border-radius: 8px;
  border-color: #dbdbdb;
}

.product-carousell-items {
  justify-content: center;
  align-items: center;
}

.product-carousell-items-slides {
  padding-left: 8.438vw;
  padding-right: 8.438vw;
}

.product-carousell-items-arrow {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-carousell-items-arrow-icon {
  color: rgb(47, 95, 169) !important;
}

.product-carousell-items-slide {
  cursor: pointer;
}

.product-carousell-content-link {
  display: inline-block;
  cursor: pointer;
  color: #113353 !important;
  border-bottom: 2px solid #f41f1f !important;
  text-align: center;
  font-size: 28px;
  font-weight: bold !important;
  font-family: "DM Sans" !important;
}

.product-spec-container {
  background-color: white;
  padding: 0px;
}

.product-spec-title-content {
  padding: 0 0 12px 27px !important;
}

.product-spec-title-text {
  font-size: 72px;
  letter-spacing: 0;
  color: #113353;
}

.product-spec-breadcrumbs-content {
  padding: 0 0 12px 27px !important;
}

.product-spec-breadcrumbs {
  font-size: 11px !important;
  font-weight: normal;
}

.product-spec-info-padding {
  padding: 6px 0 12px 21px !important;
}

.product-spec-info-justify {
  text-align: justify !important;
}

.product-spec-subtitle-text {
  font-size: 24px;
  letter-spacing: -0.1px;
  line-height: 36px;
  color: #113353;
}

.product-spec-body-text {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  color: #757575;
}

.product-spec-info-section-title {
  font-size: 42px;
  letter-spacing: -0.3px;
  color: #113353;
}

.product-spec-info-items-text {
  font-size: 24px;
  letter-spacing: -0.1px;
  color: #113353;
}

.product-spec-feature-select-content {
  margin-top: 6px;
  margin-bottom: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.product-spec-feature-model-text {
  font-size: 24px;
  letter-spacing: -0.1px;
  color: #757575;
}

.product-spec-feature-items {
  margin-top: 6px;
  margin-bottom: 6px;
}

.product-spec-feature-items-value-text {
  font-size: 24px;
  letter-spacing: -0.1px;
  color: #757575;
}

.product-spec-feature-items-last {
  margin-top: 6px;
}

.product-spec-doc-button {
  color: white !important;
  text-transform: capitalize !important;
  font-weight: lighter !important;
  letter-spacing: normal !important;
  font-family: "DM Sans" !important;
  font-stretch: expanded !important;
}

.vueperslide--active {
  border: 1px solid #d5d5d5 !important;
  border-bottom: 2px solid #f41f1f !important;
}

.v-application .details .descripcion {
  line-height: 1.1 !important;
}
.v-application .details .bdescripcion {
  line-height: 1.2 !important;
}
.details .v-breadcrumbs {
  padding: 0px !important;
}
.details .v-breadcrumbs li {
  font-size: 11px !important;
}
.v-breadcrumbs__item {
  font-size: 15px !important;
  font-weight: 500;
  letter-spacing: 0;
  color: #295aa6 !important;
  font-family: "DM Sans" !important;
}
.v-breadcrumbs__item--disabled {
  font-size: 15px !important;
  font-weight: 500;
  letter-spacing: 0;
  color: #113353 !important;
  font-family: "DM Sans" !important;
}
.theme--light.v-divider {
  color: rgb(198, 198, 198) !important;
}
.wrapImg {
  border-radius: 4px;
  border: 1px solid rgb(198, 198, 198);
}
.details .effect {
  box-shadow: 0 0 0 0;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #f8f8f8;
}
.v-select > .v-input__control > .v-input__slot {
  cursor: pointer;
  font-size: 14px;
  font-family: "DM Sans";
}

.theme--light.v-select .v-select__selection--comma {
  color: rgba(255, 255, 255, 0.87);
}

.v-input,
.v-select__selection,
input {
  color: #7c7c7c !important;
}
@media screen and (max-width: 1600px) {
  .product-spec-title-text {
    font-size: 4.5vw;
    letter-spacing: 0;
    color: #113353;
  }
  .product-spec-subtitle-text {
    font-size: 1.5vw;
    letter-spacing: -0.006vw;
    line-height: 2.25vw;
    color: #113353;
  }

  .product-spec-body-text {
    font-size: 1.125vw;
    letter-spacing: 0;
    line-height: 1.875vw;
    color: #757575;
  }
  .product-spec-info-items-text {
    font-size: 1.5vw;
    letter-spacing: -0.006vw;
    color: #113353;
  }
  .product-spec-feature-items-value-text {
    font-size: 1.5vw;
    letter-spacing: -0.006px;
    color: #757575;
  }
  .product-carousell-content-link {
    display: inline-block;
    cursor: pointer;
    color: #113353 !important;
    border-bottom: 0.125vw solid #f41f1f !important;
    text-align: center;
    font-size: 1.75vw;
    font-weight: bold !important;
    font-family: "DM Sans" !important;
  }
  .product-spec-info-section-title {
    font-size: 2.625vw;
    letter-spacing: -0.019vw;
    color: #113353;
  }
}

@media screen and (max-width: 769px) {
  .product-spec-title-text {
    font-size: 56px;
    letter-spacing: 0;
    color: #113353;
  }
  .product-spec-subtitle-text {
    font-size: 21px;
    letter-spacing: -0.1px;
    line-height: 36px;
    color: #113353;
  }

  .product-spec-body-text {
    font-size: 21px;
    letter-spacing: 0;
    line-height: 30px;
    color: #757575;
  }
  .product-spec-info-items-text {
    font-size: 21px;
    letter-spacing: -0.1px;
    color: #113353;
  }
  .product-spec-feature-model-text {
    font-size: 21px;
    letter-spacing: -0.1px;
    color: #757575;
  }
  .product-spec-feature-items-value-text {
    font-size: 21px;
    letter-spacing: -0.1px;
    color: #757575;
  }
  .product-carousell-content-link {
    display: inline-block;
    cursor: pointer;
    color: #113353 !important;
    border-bottom: 2px solid #f41f1f !important;
    text-align: center;
    font-size: 20px;
    font-weight: bold !important;
    font-family: "DM Sans" !important;
  }
  .product-spec-info-section-title {
    font-size: 26px;
    letter-spacing: -0.1px;
    color: #113353;
  }
}

@media screen and (max-width: 767px) {
  .product-spec-title-text {
    font-size: 7.292vw !important;
    letter-spacing: 0;
    color: #113353;
  }
  .product-spec-subtitle-text {
    font-size: 2.734vw;
    letter-spacing: -0.013vw;
    line-height: 4.688vw;
    color: #113353;
  }

  .product-spec-body-text {
    font-size: 2.734vw;
    letter-spacing: 0;
    line-height: 3.906vw;
    color: #757575;
  }
  .product-spec-info-items-text {
    font-size: 2.734vw;
    letter-spacing: -0.013vw;
    color: #113353;
  }
  .product-spec-feature-model-text {
    font-size: 2.734vw;
    letter-spacing: -0.013vw;
    color: #757575;
  }
  .product-spec-feature-items-value-text {
    font-size: 2.734vw;
    letter-spacing: -0.013vw;
    color: #757575;
  }
  .product-carousell-content-link {
    display: inline-block;
    cursor: pointer;
    color: #113353 !important;
    border-bottom: 0.26vw solid #f41f1f !important;
    text-align: center;
    font-size: 2.604vw;
    font-weight: bold !important;
    font-family: "DM Sans" !important;
  }
  .product-spec-info-section-title {
    font-size: 3.385vw;
    letter-spacing: -0.019vw;
    color: #113353;
  }
}

@media screen and (max-width: 426px) {
  .product-carousell-content-link {
    display: inline-block;
    cursor: pointer;
    color: #113353 !important;
    border-bottom: 1px solid #f41f1f !important;
    text-align: center;
    font-size: 15px;
    font-weight: bold !important;
    font-family: "DM Sans" !important;
  }
  .v-breadcrumbs li:nth-child(even) {
    padding: 0 0px;
  }
  .product-spec-title-text {
    font-size: 22px;
    letter-spacing: 0;
    color: #113353;
  }
  .product-spec-subtitle-text {
    font-size: 16px;
    letter-spacing: -0.1px;
    line-height: 23px;
    color: #113353;
    margin-bottom: 20px !important;
  }
  .product-spec-body-text {
    font-size: 16px !important;
    letter-spacing: 0;
    line-height: 20px;
    color: #757575;
  }
  .product-spec-title-content {
    padding: 0 !important;
  }
  .product-spec-breadcrumbs-content {
    padding: 0 !important;
  }
  .product-spec-info-padding {
    padding: 0 !important;
    margin-top: 20px !important;
  }
  .product-spec-info-section-title {
    font-size: 22px;
    letter-spacing: -0.019vw;
    color: #113353;
  }

  .product-spec-info-items-text {
    font-size: 16px;
    letter-spacing: -0.013vw;
    color: #113353;
  }
  .product-carousell-items-slides {
    padding-left: 58px;
    padding-right: 58px;
  }
  .product-spec-feature-items-value-text {
    font-size: 16px;
    letter-spacing: -0.013vw;
    color: #757575;
  }
  .product-spec-feature-model-text {
    font-size: 16px;
    letter-spacing: -0.1px;
    color: #757575;
  }
}

@media screen and (max-width: 424px) {
  .product-carousell-content-link {
    display: inline-block;
    cursor: pointer;
    color: #113353 !important;
    border-bottom: 0.235vw solid #f41f1f !important;
    text-align: center;
    font-size: 3.521vw;
    font-weight: bold !important;
    font-family: "DM Sans" !important;
  }
  .v-breadcrumbs li:nth-child(even) {
    padding: 0 0px;
  }
  .product-spec-title-text {
    font-size: 5.164vw;
    letter-spacing: 0;
    color: #113353;
  }
  .product-spec-subtitle-text {
    font-size: 3.756vw;
    letter-spacing: -0.023vw;
    line-height: 5.399vw;
    color: #113353;
    margin-bottom: 4.695vw !important;
  }
  .product-spec-body-text {
    font-size: 3.756vw;
    letter-spacing: 0;
    line-height: 4.695vw;
    color: #757575;
  }
  .product-spec-title-content {
    padding: 0 !important;
  }
  .product-spec-breadcrumbs-content {
    padding: 0 !important;
  }
  .product-spec-info-padding {
    padding: 0 !important;
    margin-top: 4.695vw !important;
  }
  .product-spec-info-section-title {
    font-size: 5.164vw;
    letter-spacing: -0.019vw;
    color: #113353;
  }

  .product-spec-info-items-text {
    font-size: 3.756vw;
    letter-spacing: -0.013vw;
    color: #113353;
  }
  .product-carousell-items-slides {
    padding-left: 13.615vw;
    padding-right: 13.615vw;
  }
  .product-spec-feature-items-value-text {
    font-size: 3.756vw;
    letter-spacing: -0.013vw;
    color: #757575;
  }
  .product-spec-feature-model-text {
    font-size: 3.756vw;
    letter-spacing: -0.023vw;
    color: #757575;
  }
  .v-breadcrumbs__item {
    font-size: 3.521vw !important;
    font-weight: 500;
    letter-spacing: 0;
    color: #295aa6 !important;
    font-family: "DM Sans" !important;
  }
  .v-breadcrumbs__item--disabled {
    font-size: 3.521vw !important;
    font-weight: 500;
    letter-spacing: 0;
    color: #113353 !important;
    font-family: "DM Sans" !important;
  }
}
</style>
