<template>
  <div>
    <v-app-bar
      class="application-bar"
      flat
      height="85"
      style="border-bottom: 1px solid #f4f4f4"
    >
      <v-row no-gutters>
        <v-img
          v-if="!hideLogo"
          :class="[responsiveLogoStyle, `shrink `]"
          contain
          @click="$router.push('/')"
          :src="responsiveLogoImg"
        ></v-img>
        <v-col cols="auto" :class="responsiveLogoStyle2"> </v-col>

        <v-spacer></v-spacer>

        <v-col v-if="!hideMenu" class="category-col" cols="auto">
          <div class="text-center">
            <v-menu
              min-width="100%"
              open-on-hover
              close-on-content-click
              offset-y
              tile
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" color="transparent">
                  <span
                    class="textHeader header-menu-btn"
                    @click="$router.push('/productos')"
                  >
                    {{ $t("Products") }}
                  </span>
                </v-btn>
              </template>

              <v-col
                class="header-menu-style "
                align="center"
                cols="auto"
                style="width:1602px; margin-left: auto; margin-right: auto; border-top: 1px solid #f4f4f4"
              >
                <v-row no-gutters justify="center" style="height:215px">
                  <v-col
                    class="effect"
                    v-for="card in cards"
                    :key="card.title"
                    cols="auto"
                  >
                    <v-card
                      flat
                      :to="{ name: card.slug }"
                      style="color:transparent"
                    >
                      <v-col class="products-menu-col">
                        <v-col class="products-menu-col-min" align="center">
                          <v-img
                            :src="card.src"
                            :height="card.height"
                            :width="card.width"
                          >
                          </v-img>
                        </v-col>

                        <v-col cols="auto" class="products-menu-text">
                          <span class="products-menu-title">
                            {{ card.title }}
                          </span>

                          <v-spacer></v-spacer>

                          <span class="products-menu-subtitle">
                            {{ card.subtitle }}
                          </span>
                        </v-col>
                      </v-col>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-menu>
          </div>

          <v-btn
            v-for="(item, index) in menuBars.items"
            :key="index"
            text
            color="transparent"
          >
            <a class="header-menu-btn" :href="item.url ? item.url : null">
              {{ item.title }}
            </a>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col v-if="!hideLang" class="category-col" cols="auto">
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="language-btn" text v-bind="attrs" v-on="on">
                  <v-icon
                    class="web-language-icon"
                    size="22px !important"
                    :style="{ color: colorIcon }"
                  >
                    mdi-web
                  </v-icon>
                  <span
                    v-if="responsiveLangText"
                    class="language-text"
                    :style="{ color: colorText }"
                  >
                    {{ currentLang }}
                  </span>
                  <v-icon size="14px !important" :style="{ color: colorText }"
                    >mdi-chevron-down</v-icon
                  >
                </v-btn>
              </template>
              <v-list class="language-list">
                <v-list-item
                  class="language-list-item"
                  v-for="(item, index) in globalLanguages.items"
                  :key="index"
                  link
                  hide-details
                  @click="changeLang(item)"
                >
                  <v-list-item-title
                    class="fontSicom language-list-item-title"
                    >{{ item.title }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <v-col cols="auto" class="search-btn-margin category-col">
          <v-btn
            v-if="!hideSearchBtn && !active"
            @click="active = true"
            class="search-btn"
            text
            icon
          >
            <eva-icon
              name="search"
              animation="pulse"
              fill="rgb(49,97,162)"
            ></eva-icon>
          </v-btn>
          <v-col v-if="active" class="search-textfield">
            <v-text-field
              v-if="active"
              v-click-outside="onClickOutside"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :color="colorIcon"
              outlined
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-col>

        <v-col class="end-col" cols="auto"></v-col>
      </v-row>
    </v-app-bar>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    logo: {
      default: require("@/assets/SICOM2020.png"),
      require: false,
    },
    logo2: {
      default: require("@/assets/sicomlogomin.png"),
      require: false,
    },
    hideSearchBtn: {
      type: Boolean,
      default: false,
      require: true,
    },
    hideLogo: {
      type: Boolean,
      default: false,
      require: true,
    },
    hideMenu: {
      type: Boolean,
      default: false,
      require: true,
    },
    hideLang: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data: () => ({
    number: 100,
    username: undefined,
    active: false,
    search: "",
    currentLang: "Español",
    userMenuItems: [
      {
        text: "Instance Details",
        icon: "mdi-magnify",
        slug: "details",
      },
      {
        text: "Instance Configuration",
        icon: "mdi-wrench",
        slug: "generalConfig",
      },
      {
        text: "Account Settings",
        icon: "mdi-account-box",
        slug: "myAccount",
      },
    ],
    cards: [
      {
        title: "Cámaras",
        src: require("@/assets/img/image3_min.png"),
        width: "43px",
        height: "64px",
        flex: 1,
        slug: "Camera",
      },
      {
        title: "Video Timbres",
        src: require("@/assets/img/image_min.png"),
        width: "26px",
        height: "64px",
        flex: 2,
        slug: "Ring",
      },
      {
        title: "Smart NVR",
        src: require("@/assets/img/image1_min.png"),
        width: "64px",
        height: "64px",
        subtitle: "Nuevo",
        flex: 2,
        slug: "",
      },
    ],
  }),

  computed: {
    globalLanguages() {
      return {
        items: [
          { title: this.$i18n.t("Global_Language_Esp"), value: "es" },
          { title: this.$i18n.t("Global_Language_Eng"), value: "en" },
        ],
      };
    },

    menuBars() {
      return {
        items: [
          /* { title: this.$i18n.t("Products") }, */
          {
            title: this.$i18n.t("Shop"),
            url: "https://dev.web-shop4.ledpack.cl/",
          },
          { title: this.$i18n.t("Resources") },
        ],
      };
    },

    colorIcon() {
      return this.color
        ? this.color + "!important"
        : "rgb(49,97,162) !important";
    },
    colorText() {
      return this.textColor
        ? this.textColor + "!important"
        : "rgb(41, 73, 99) !important";
    },
    responsiveHeight() {
      return this.$vuetify.breakpoint.smAndUp ? 85 : 68;
    },
    responsiveLogoImg() {
      return this.$vuetify.breakpoint.smAndUp ? this.logo : this.logo2;
    },
    responsiveLogoStyle() {
      return this.$vuetify.breakpoint.smAndUp
        ? `logoSicom-smAndUp`
        : `logoSicom-smAndDown`;
    },
    responsiveLogoStyle2() {
      return this.$vuetify.breakpoint.smAndUp
        ? `logoSicom-col`
        : `logoSicom-col-smAndDown`;
    },
    responsiveLangText() {
      return this.$vuetify.breakpoint.smAndUp;
    },
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang.value;
      const arrLang = [
        { title: this.$i18n.t("Global_Language_Esp"), value: "es" },
        { title: this.$i18n.t("Global_Language_Eng"), value: "en" },
      ];
      const language = arrLang.find((item) => item.value === lang.value);
      this.currentLang = language.title;
    },
    navigate(route) {
      this.$router.replace({ name: route });
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    onClickOutside() {
      this.active = false;
    },
  },
};
</script>

<style scoped>
.fontSicom {
  font-family: DM Sans;
}
.application-bar {
  background-color: rgb(255, 255, 255) !important;
}
.logoSicom-col {
  justify-content: center;
  align-items: center;
}
.logoSicom-col-smAndDown {
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoSicom-smAndUp {
  cursor: pointer;
  width: 140px;
  height: 65px;
}
.logoSicom-smAndDown {
  cursor: pointer;
  width: 140px;
  height: 65px;
}

.web-language-icon {
  margin-top: 0.104vw;
  margin-right: 0.104vw;
}
.language-btn {
  text-transform: capitalize;
  padding-left: 0.83vw !important;
  padding-right: 0.83vw !important;
}
.language-text {
  font-family: DM Sans !important;
  font-weight: 700;
  font-size: 16px !important;
  letter-spacing: 0vw;
}
.language-list-item {
  min-height: 1.875vw !important;
}
.language-list {
  border-radius: 0vw !important;
  max-height: auto !important;
}
.language-list-item-title {
  font-family: DM Sans !important;
  font-size: 14px !important;
}
.search-btn {
  padding-top: 0.156vw;
}
.search-btn-margin {
  margin-left: 0.208vw;
  margin-right: 0.208vw;
}
.search-textfield {
  width: 160px;
}
.end-col {
  margin-right: 0px;
}
/* .ripple-color {
  color: green !important;
  caret-color: blue !important;
} */

.header-menu-style {
  background-color: white;
  padding: 0px;
  margin-top: 15px;
}
.products-menu-col {
  height: 180px;
  width: 200px;
  padding: 0px;
  border: 1px solid white;
}
.products-menu-col-min {
  padding: 0px;
  height: 100px;
  width: 100px;
  padding-top: 18px;
  margin-top: 33px;
}
.products-menu-text {
  padding: 0px;
  margin-top: 15px;
  line-height: 1;
}
.products-menu-title {
  font-family: Noto Sans JP !important;
  font-size: 15px;
  color: #424242;
  text-transform: capitalize;
}
.products-menu-subtitle {
  font-family: Noto Sans JP !important;
  font-size: 15px;
  color: #1998d5;
  text-transform: none;
  font-weight: 600;
}
.header-menu-btn {
  font: DM Sans;
  text-transform: capitalize;
  color: #424242;
  font-weight: normal;
  text-decoration: none;
  font-size: 18px;
}
</style>
<style scoped>
.effect:hover {
  font-weight: 600;
}
.v-menu__content {
  box-shadow: none !important;
}
.v-toolbar__content,
.v-toolbar__extension {
  padding: 0px 29px !important;
}
.category-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
